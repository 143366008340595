import { Box, Fade, SxProps, useTheme } from '@mui/material';
import { forwardRef, ReactNode, useState } from 'react';
import SidebarSearch from '@/components/Sidebar/components/SidebarSearch';
import SidebarCollapseButton from '@/components/Sidebar/components/SidebarCollapseButton';
import { SIDEBAR_WIDTH } from '@/components/Sidebar/constants';

export type SidebarProps = {
  sx?: SxProps;
  searchText: string;
  children?: ReactNode;
  action?: ReactNode;
  backgroundColor?: string;
  onSearchTextChange: (text: string) => void;
  isOpenedByDefault?: boolean;
};

const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
  ({ sx, searchText, children, action, backgroundColor, onSearchTextChange, isOpenedByDefault = true }, ref) => {
    const { palette } = useTheme();
    const [isOpen, setIsOpen] = useState(isOpenedByDefault);

    const handleCollapseClick = () => setIsOpen(prevState => !prevState);

    return (
      <Box
        ref={ref}
        sx={{
          position: 'relative',
          flex: '0 0 auto',
          width: isOpen ? SIDEBAR_WIDTH : 49,
          height: '100%',
          transition: 'width .3s',
          overflowX: 'hidden',
          overflowY: 'auto',
          backgroundColor: backgroundColor ?? palette.background.default,
          ...sx,
        }}
      >
        <Fade in={!isOpen}>
          <SidebarCollapseButton
            sx={{ position: 'absolute', top: 2, zIndex: isOpen ? -1 : 2 }}
            iconSx={{ transform: 'scale(-1, 1)' }}
            onClick={handleCollapseClick}
          />
        </Fade>

        <Fade in={isOpen}>
          <Box sx={{ width: SIDEBAR_WIDTH, height: '100%', overflow: isOpen ? 'visible' : 'hidden' }}>
            <SidebarSearch
              sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: backgroundColor ?? palette.background.default }}
              searchText={searchText}
              action={action}
              onSearchTextChange={onSearchTextChange}
              onCollapseClick={handleCollapseClick}
            />
            {children}
          </Box>
        </Fade>
      </Box>
    );
  },
);

export default Sidebar;
