import { z } from 'zod';
import { TagType } from '@/api/generated';

export const questions = z.object({
  _id: z.string().optional(),
  question: z.string().min(1),
  reference: z.string().optional().nullable(),
  tags: z
    .array(
      z.object({
        name: z.string(),
        type: z.enum([TagType.persona, TagType.query, TagType.equipment, TagType.project, TagType.organization]),
      }),
    )
    .nullable()
    .optional(),
});

export const validationSchema = z.object({
  _id: z.string().optional(),
  name: z.string().min(1, { message: 'queries.checklistsEditDialog.validation.name' }),
  description: z.string().optional(),
  searchQuestionValue: z.string().optional(),
  checklistQuestions: z.array(questions).optional(),
});
