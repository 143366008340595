import { ReactNode } from 'react';
import { SxProps, Typography, useTheme } from '@mui/material';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import TextInput, { TextInputProps } from '@/components/TextInput/TextInput';
import EditableField, { EditableFieldProps } from '@/components/EditableField';

interface TextInputWithEditButtonProps<T extends FieldValues = FieldValues>
  extends Omit<TextInputProps<T>, 'children'>,
    Omit<EditableFieldProps, 'children' | 'displayedText'> {
  control: Control<T>;
  name: FieldPath<T>;
  isOpened: boolean;
  isEditable?: boolean;
  onOpenClick: () => void;
  containerSx?: SxProps;
  addLabel?: boolean;
}

const TextInputWithEditButton: <T extends FieldValues>(props: TextInputWithEditButtonProps<T>) => ReactNode = ({
  labelSx,
  containerSx,
  isOpened,
  isEditable = true,
  control,
  name,
  onOpenClick,
  ...props
}) => {
  const { palette } = useTheme();
  const { field } = useController({ control, name });

  return (
    <EditableField
      containerSx={containerSx}
      isOpened={isOpened}
      isEditable={isEditable}
      displayedText={
        <Typography sx={{ fontSize: 'h2.fontSize', fontWeight: 700, color: palette.controls.label }}>{field.value}</Typography>
      }
      onOpenClick={onOpenClick}
    >
      <TextInput autoFocus labelSx={{ fontWeight: 700, ...labelSx }} control={control} name={name} {...props} />
    </EditableField>
  );
};

export default TextInputWithEditButton;
