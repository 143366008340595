import { forwardRef, ReactNode } from 'react';
import { MenuList, Paper, SxProps } from '@mui/material';

interface AiMenuProps {
  children: ReactNode;
  sx?: SxProps;
}

const AiMenu = forwardRef<HTMLDivElement, AiMenuProps>(({ children, sx }, ref) => (
  <Paper ref={ref} sx={{ alignSelf: 'flex-start', minWidth: 200, overflow: 'hidden', boxShadow: 2, ...sx }}>
    <MenuList sx={{ display: 'flex', flexDirection: 'column' }} disablePadding>
      {children}
    </MenuList>
  </Paper>
));

export default AiMenu;
