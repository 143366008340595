import { Children, cloneElement, FC, ReactElement, ReactNode } from 'react';
import { Box, Dialog as MuiDialog, DialogProps as MuiDialogProps, SxProps, useTheme } from '@mui/material';
import DialogHeader from '@/components/Dialog/DialogHeader';
import DialogAppHeader from '@/components/Dialog/DialogAppHeader';
import DialogTextHeader from '@/components/Dialog/DialogTextHeader';

interface DialogProps extends MuiDialogProps {
  contentSx?: SxProps;
  width?: number | string;
}

type ChildrenType = { header?: ReactElement<typeof DialogHeader>; children: ReactNode[] };

const HeaderComponents = [DialogHeader, DialogAppHeader, DialogTextHeader];

const Dialog: FC<DialogProps> = ({ sx, contentSx, PaperProps, width = 500, children: initialChildren, onClose, ...props }) => {
  const { palette, shape } = useTheme();
  const { header, children } = Children.toArray(initialChildren).reduce<ChildrenType>(
    (acc, child) => {
      if (
        typeof child === 'object' &&
        'type' in child &&
        typeof child.type !== 'string' &&
        // @ts-expect-error
        HeaderComponents.some(component => component === child.type || component.name === child.type.name)
      ) {
        acc.header = cloneElement(child, { onClose, ...child.props });
      } else {
        acc.children.push(child);
      }
      return acc;
    },
    { children: [] },
  );

  return (
    <MuiDialog
      {...props}
      maxWidth="md"
      sx={{ ...sx, '& .MuiBackdrop-root': { backgroundColor: 'rgba(0,0,0,0.1)' } }}
      PaperProps={{
        ...PaperProps,
        sx: {
          width,
          backgroundColor: palette.background.default,
          borderRadius: shape.borderRadius,
          boxShadow: 4,
          border: `1px solid ${palette.grey[25]}`,
          ...(PaperProps?.sx ?? {}),
        },
      }}
      onClose={onClose}
    >
      {header}
      <Box sx={{ minHeight: 0, p: 4, ...contentSx }}>{children}</Box>
    </MuiDialog>
  );
};

export default Dialog;
