import { FC, ReactNode } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';

export interface DialogHeaderProps {
  sx?: SxProps;
  title: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
}

const DialogHeader: FC<DialogHeaderProps> = ({ sx, title, children, onClose }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        width: '100%',
        p: 2,
        borderBottom: '1px solid',
        borderColor: palette.custom.separationLine,
        color: palette.grey[900],
        ...sx,
      }}
    >
      {title}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {children}
        <DialogCloseButton onClick={() => onClose?.()} />
      </Box>
    </Box>
  );
};

export default DialogHeader;
