import { forwardRef } from 'react';
import { IconButton, IconButtonProps, SxProps } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { SIDEBAR_SEARCH_Y_PADDING } from '@/components/Sidebar/constants';

interface SidebarCollapseButtonProps extends IconButtonProps {
  iconSx?: SxProps;
}

const SidebarCollapseButton = forwardRef<HTMLButtonElement, SidebarCollapseButtonProps>(({ sx, iconSx, ...props }, ref) => (
  <IconButton ref={ref} size="small" disableRipple sx={{ pl: 2, py: SIDEBAR_SEARCH_Y_PADDING, ...sx }} {...props}>
    <Icon sx={iconSx} name="doubleArrowLeft" />
  </IconButton>
));

export default SidebarCollapseButton;
