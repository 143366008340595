import { FC, ReactNode } from 'react';
import { Box, MenuItem, MenuItemProps, Stack, useTheme } from '@mui/material';

export interface MenuItemWithIconProps extends Omit<MenuItemProps, 'children'> {
  isSelected?: boolean;
  icon?: ReactNode;
  text: ReactNode;
  description?: string;
}

const MenuItemWithIcon: FC<MenuItemWithIconProps> = ({ isSelected, sx, icon, text, description, ...props }) => {
  const { palette } = useTheme();

  return (
    <MenuItem {...props} sx={{ gap: 2, px: 3, ...sx }}>
      {icon}
      <Stack sx={{ gap: 0.3 }}>
        <Box sx={{ fontSize: 'body3.fontSize', color: isSelected ? palette.primary.dark : palette.grey[800], fontWeight: 700 }}>
          {text}
        </Box>
        <Box sx={{ fontSize: 'body4.fontSize', color: palette.grey[400] }}>{description}</Box>
      </Stack>
    </MenuItem>
  );
};

export default MenuItemWithIcon;
