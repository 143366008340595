import { Components, Theme } from '@mui/material/styles';

const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      '&:not(:last-child)': { borderBottom: `1px solid ${theme.palette.custom.separationLine}` },
      lineHeight: 'normal',
      backgroundColor: 'transparent',
    }),
  },
};

export default MuiListSubheader;
