import { FC } from 'react';
import { Box } from '@mui/material';
import DialogHeader, { DialogHeaderProps } from '@/components/Dialog/DialogHeader';

interface DialogTextHeaderProps extends DialogHeaderProps {}

const DialogTextHeader: FC<DialogTextHeaderProps> = ({ title, ...props }) => (
  <DialogHeader
    title={
      <Box
        sx={{
          display: 'flex',
          fontSize: 'h4.fontSize',
          fontWeight: 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Box>
    }
    {...props}
  />
);

export default DialogTextHeader;
