import { FC } from 'react';
import { CodeNode } from '@lexical/code';
import { View, Text } from '@react-pdf/renderer';

interface CodeViewProps {
  node: CodeNode;
}

const CodeView: FC<CodeViewProps> = ({ node }) => (
  <View style={{ marginVertical: 8, padding: 16, borderRadius: 2, backgroundColor: '#f0f2f5', color: '#3d3a36' }}>
    <Text>
      {node.getChildren().map(child => (
        <Text key={child.getKey()}>{child.getTextContent()}</Text>
      ))}
    </Text>
  </View>
);

export default CodeView;
