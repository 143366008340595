import { InputLabel, Stack, TextField, useTheme, SxProps } from '@mui/material';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { ReactNode } from 'react';
import { getOptionValue } from '@/utils/getOptionValue';
import Select, { SelectProps } from '@/components/Select';

export interface ListItem<T = string> {
  title: string;
  value?: T;
  inputValue?: string;
}

type Value<T> = ListItem<T>;

export interface SelectControlProps<
  T extends FieldValues = FieldValues,
  Path extends FieldPath<T> = FieldPath<T>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends SelectProps<Value<T[Path]>, Multiple, DisableClearable, FreeSolo> {
  id: string;
  placeholder?: string;
  label?: string;
  labelSx?: SxProps;
  name: Path;
  control: Control<T>;
  endAdornment?: ReactNode;
}

function SelectControl<
  T extends FieldValues = FieldValues,
  Path extends FieldPath<T> = FieldPath<T>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  label,
  labelSx,
  placeholder,
  options,
  control,
  name,
  id,
  endAdornment,
  onKeyDown,
  autoFocus,
  ...autocompleteProps
}: SelectControlProps<T, Path, Multiple, DisableClearable, FreeSolo>) {
  const { palette } = useTheme();
  const { field, fieldState } = useController({ name, control });
  const { ref, ...fieldProps } = field;

  return (
    <Stack gap={0.6} sx={{ width: '100%' }}>
      {label && (
        <InputLabel sx={{ color: palette.controls.label, ...labelSx }} htmlFor={id}>
          {label}
        </InputLabel>
      )}

      <Select<Value<T[Path]>, Multiple, DisableClearable, FreeSolo>
        {...fieldProps}
        // @ts-expect-error
        value={options.find(option => getOptionValue(option) === field.value)}
        getOptionLabel={option => (typeof option === 'string' ? option : option.title)}
        // @ts-expect-error
        onChange={(_, option) => field.onChange(getOptionValue(option))}
        id={id}
        selectOnFocus
        handleHomeEndKeys
        openOnFocus
        options={options}
        {...autocompleteProps}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {endAdornment}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={!!fieldState.error}
            placeholder={placeholder}
          />
        )}
      />
    </Stack>
  );
}

export default SelectControl;
