import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { NodeApi, NodeRendererProps } from 'react-arborist';
import { Box, ButtonBase, Checkbox, CircularProgress, IconButton, Input, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';
import { STATUS } from '@/utils/enums';
import { Key } from 'ts-key-enum';
import { BsThreeDots } from 'react-icons/bs';
import Actions from '@/views/Projects/components/Actions/Actions';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { MenuItemProps } from '@/components/MoreActions/MoreActions';
import { TreeFileSystemNode } from '@/types';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import DocumentIcon from '@/views/Projects/components/ProjectFormDialog/components/DocumentIcon';
import { format } from 'date-fns/format';
import { useSelector } from 'react-redux';
import { isNodeSelected, toggleSelectedNode } from '@/store/fileTreeSlice';
import { useAppDispatch } from '@/store';

interface TreeDocumentNodeProps extends NodeRendererProps<TreeFileSystemNode> {
  slug?: string;
  onAddFile: (node: NodeApi<TreeFileSystemNode>) => void;
  onNodeClick: (node: NodeApi<TreeFileSystemNode>) => void;
  onAddVersion: (node: NodeApi<TreeFileSystemNode>) => void;
  onDocumentConvertToPage: (documentId: string) => void;
}

const TreeDocumentNode: FC<TreeDocumentNodeProps> = ({
  node,
  slug,
  style,
  dragHandle,
  tree,
  onAddFile,
  onNodeClick,
  onAddVersion,
  onDocumentConvertToPage,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const actionsState = usePopupState({
    variant: 'popover',
    popupId: `tree-file-system-actions-${node.data.id}`,
  });
  const dispatch = useAppDispatch();

  const { type, name, status, document, versions } = node.data;
  const isReadOnly = status !== STATUS.LOADED;
  const [documentName, setDocumentName] = useState(name);
  const [isDragOver, setIsDragOver] = useState(false);
  const isLoading = node.data.status === STATUS.LOADING;
  const isFailed = node.data.status === STATUS.ERROR;

  const lastModified = document && document.last_modified ? format(document.last_modified, 'P') : '';
  const version = document ? versions && Math.max(versions.findIndex(v => v.id === document._id) + 1, 1) : undefined;
  const documentType = document?.document_type?.main_type;

  const elementBgColor =
    (node.data.type === 'folder' && isDragOver) || node.willReceiveDrop || node.isSelected || node.isFocused
      ? palette.grey[50]
      : 'inherit';

  const save = () => documentName.trim() && node.submit(documentName.trim());

  const onAddFolder = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    tree.create({ parentId: node.id });
  };

  const onDelete = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    tree.delete(node);
  };

  const onEdit = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    tree.edit(node);
  };

  const onRootClick = (event: MouseEvent) => {
    if (node.isEditing || isReadOnly || event.ctrlKey || event.metaKey) return;

    const isClickedOnButton = (event.target as HTMLElement).closest('button');

    if (node.data.type === 'folder' && !isClickedOnButton) {
      node.toggle();
    } else {
      onNodeClick(node);
    }
  };

  const handleAddFile = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    onAddFile(node);
  };

  const handleAddVersion = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    onAddVersion(node);
  };

  const onDragEnter = () => setIsDragOver(true);
  const onDragLeave = () => setIsDragOver(false);

  const onKeyDown = (event: KeyboardEvent) => event.code == Key.Enter && save();
  const onBlur = () => save();

  const menuItems: MenuItemProps[] = [
    type === 'folder' && {
      id: 'uploadFiles.addFolder',
      children: t('uploadFiles.addSubFolder'),
      onClick: onAddFolder,
    },
    {
      id: 'uploadFiles.delete',
      children: type === 'folder' ? t('uploadFiles.deleteFolder') : t('uploadFiles.deleteFile'),
      onClick: onDelete,
    },
    { id: 'actions.edit', children: t('uploadFiles.rename'), onClick: onEdit },
    false &&
      type !== 'folder' && {
        id: 'actions.convert',
        children: t('uploadFiles.convert.menuItem'),
        onClick: () => onDocumentConvertToPage(node.id),
      },
  ].filter(item => typeof item !== 'boolean');

  useEffect(() => {
    setDocumentName(node.data.name);
  }, [node.data]);

  const isChecked = useSelector(isNodeSelected(slug, node.id));
  const onCheckboxClick = () => {
    slug && dispatch(toggleSelectedNode({ slug, node: node.data }));
  };

  return (
    <Stack
      ref={dragHandle}
      style={style}
      data-folderid={node.data.type === 'folder' ? node.data.id : undefined}
      title={isFailed ? (node.data.type === 'folder' ? t('uploadFiles.toggleFolder') : t('uploadFiles.openFile')) : undefined}
      sx={{
        position: 'relative',
        height: '100%',
        fontWeight: 400,
        borderRadius: 1,
        borderTop: node.isDragging ? '1px dashed' : 'none',
        borderBottom: node.isDragging ? '1px dashed' : 'none',
        backgroundColor: elementBgColor,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: node.isSelected || node.isFocused ? '' : palette.grey[25],
          '& .TreeDocumentNode__actions': { opacity: 1 },
        },
      }}
      onClick={onRootClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} sx={{ height: '100%', px: 3 }}>
        <Stack direction="row" alignItems="center" gap={1} sx={{ minWidth: 0, flex: 1 }}>
          <Box onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isChecked}
              onChange={onCheckboxClick}
              size="small"
              sx={{ p: 0.75, mr: -1 }}
              icon={<Icon name="unCheckSquare" fontSize="medium" htmlColor={palette.grey[600]} />}
              checkedIcon={<Icon name="checkSquare1" fontSize="medium" htmlColor={palette.grey[900]} />}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 24, height: 24 }}>
            {node.data.status === STATUS.DELETING ? (
              <CircularProgress size={16} />
            ) : (
              <DocumentIcon node={node} htmlColor={palette.grey['900']} sx={{ flexShrink: 0 }} />
            )}
          </Box>

          {node.isEditing ? (
            <Input
              autoFocus
              value={documentName}
              onChange={event => setDocumentName(event.target.value)}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                fontSize: 'body2.fontSize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                pr: 2,
                color: isLoading || isFailed ? palette.grey[400] : palette.text.primary,
              }}
            >
              {documentName}
            </Box>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap={1} sx={{ minWidth: 0, width: 80 }}>
          {documentType && (
            <Typography variant="body2" color={palette.grey[700]}>
              {documentType}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap={1} sx={{ minWidth: 0, width: 80 }}>
          {!!version && (
            <Typography variant="body2" color={palette.grey[700]}>
              V{version}
            </Typography>
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ minWidth: 0, width: 120, height: '100%', mr: -2.5, position: 'relative' }}
        >
          <Typography variant="body2" color={palette.grey[700]}>
            {lastModified}
          </Typography>
          {!isReadOnly && (
            <Stack
              direction="row"
              alignItems="center"
              className="TreeDocumentNode__actions"
              sx={{
                opacity: actionsState.isOpen ? 1 : 0,
                position: 'absolute',
                right: 0,
                pr: 1,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
                backgroundColor: palette.grey[25],
                whiteSpace: 'nowrap',
              }}
            >
              {type === 'file' && (
                <ButtonBase
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                    px: 1,
                    py: 0.2,
                    border: '1px solid',
                    borderColor: palette.primary.contrastText,
                    borderRadius: 3,
                    color: palette.primary.contrastText,
                  }}
                  onClick={handleAddVersion}
                >
                  <Icon name="plus" fontSize="xsmall" htmlColor={palette.primary.dark} />
                  {t('uploadFiles.addVersion')}
                </ButtonBase>
              )}

              {type === 'folder' && (
                <IconButton
                  size="small"
                  title={t('uploadFiles.addFile')}
                  sx={{ mr: -1, '&:hover': { backgroundColor: palette.primary.main } }}
                  onClick={handleAddFile}
                >
                  <Icon name="plus" fontSize="small" htmlColor={palette.primary.dark} />
                </IconButton>
              )}

              <Actions
                stopPropagation
                icon={<BsThreeDots color={palette.primary.dark} size={16} />}
                sx={{ ml: 0.5, '&:hover': { backgroundColor: palette.primary.main }, zIndex: 1 }}
                menuItems={menuItems}
                actionsState={actionsState}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      {isLoading && (
        <LinearProgressWithLabel
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: -6,
            mx: 4,
          }}
          height={2}
          progress={node.data.progress}
        />
      )}
    </Stack>
  );
};

export default TreeDocumentNode;
