import { Typography, Stack, CircularProgress, useTheme, InputLabel, Autocomplete, TextField } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem } from '@/components/SelectControl';
import { useAuth } from '@/hooks/useAuth';
import { User, useSearchUsers, shareProject, unshareProject, ProjectFull, ProjectResponse } from '@/api/generated';
import { useDebounceValue } from 'usehooks-ts';
import { UserItem } from '@/views/ShareProjectDialog/UserItem';
import Dialog from '@/components/Dialog/Dialog';
import { getOptionValue } from '@/utils/getOptionValue';
import DialogTextHeader from '@/components/Dialog/DialogTextHeader';

interface ShareProjectProps {
  project: ProjectFull | ProjectResponse;
  onShare?: (user: User) => void;
  onUnshare?: (user: User) => void;
  toggleModal: () => void;
  isOpen: boolean;
}

const QUERY_KEY = 'search-user';

const ShareProjectDialog: FC<ShareProjectProps> = ({ project, isOpen, toggleModal, onShare, onUnshare }) => {
  const { palette } = useTheme();
  const { currentUser } = useAuth();
  const { t } = useTranslation('share');

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounceValue(search, 200);

  const { data: usersToShareWith = [], isFetching } = useSearchUsers(
    debouncedSearch,
    {},
    { query: { queryKey: [QUERY_KEY, debouncedSearch], enabled: !!debouncedSearch, staleTime: 1000 * 60 } },
  );

  const options = useMemo(
    () =>
      usersToShareWith
        .filter(({ _id }) => !project.collaborate_with?.find(user => user._id === _id) && _id !== currentUser?._id)
        .map(user => {
          const { firstName, lastName, email } = user;
          return { title: t('searchOptionLabel', { firstName, lastName, email }), value: email } as ListItem;
        }),
    [usersToShareWith],
  );

  const onChange = (value: string) => {
    if (!value) return;

    const [selectedUser] = usersToShareWith.filter(({ email }) => value === email);
    if (!selectedUser || (project?.collaborate_with || []).includes(selectedUser)) return;

    shareProject(project.slug, selectedUser);
    onShare?.(selectedUser);
    setSearch('');
  };

  const onClose = () => {
    toggleModal();
    setSearch('');
  };

  const handleUnshare = (user: User) => {
    unshareProject(project.slug, { user_id: user._id! });
    onUnshare?.(user);
  };

  const collaborationUsers = project.collaborate_with?.filter(user => user._id !== currentUser?._id);

  return (
    <Dialog width={435} open={isOpen} PaperProps={{ sx: { pb: 0 } }} onClose={onClose}>
      <DialogTextHeader title={t('title', { projectName: project.name })} />
      <Stack sx={{ height: '100%' }} gap={2}>
        <Stack gap={0.6}>
          <InputLabel sx={{ color: palette.controls.label }} htmlFor="share-user-search">
            {t('searchInputLabel')}
          </InputLabel>

          <Autocomplete
            id="share-user-search"
            sx={{
              '& .MuiAutocomplete-clearIndicator': {
                visibility: search ? 'visible' : 'hidden !important',
              },
            }}
            options={options}
            clearOnBlur
            freeSolo
            inputValue={search}
            onInputChange={(_, value, reason) => setSearch(reason === 'reset' ? '' : value)}
            getOptionLabel={option => (typeof option === 'string' ? option : option.title)}
            onChange={(_, option) => option && onChange(getOptionValue(option)!)}
            selectOnFocus
            handleHomeEndKeys
            openOnFocus
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <Typography variant="body1" component="h2" sx={{ color: palette.grey[900] }}>
          {collaborationUsers?.length ? t('sharedWith') : t('sharedWithNobody')}
        </Typography>
        <Stack sx={{ overflowY: 'auto', height: 200, mr: -2, scrollbarWidth: 'thin' }}>
          {collaborationUsers?.map(user => <UserItem key={user._id} user={user} onUnshare={() => handleUnshare(user)} />)}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ShareProjectDialog;
