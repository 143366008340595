import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import AiIcon from '@/components/Icon/svgs/ai.svg?react';
import ArrowDownIcon from '@/components/Icon/svgs/arrow-down.svg?react';
import BulletListIcon from '@/components/Icon/svgs/bullet-list.svg?react';
import CodeIcon from '@/components/Icon/svgs/code.svg?react';
import SmallChecklistIcon from '@/components/Icon/svgs/small-checklist.svg?react';
import H1Icon from '@/components/Icon/svgs/H1.svg?react';
import H2Icon from '@/components/Icon/svgs/H2.svg?react';
import H3Icon from '@/components/Icon/svgs/H3.svg?react';
import NumberedListIcon from '@/components/Icon/svgs/numbered-list.svg?react';
import QuoteIcon from '@/components/Icon/svgs/quote.svg?react';
import TextIcon from '@/components/Icon/svgs/text.svg?react';
import TextTIcon from '@/components/Icon/svgs/text-t.svg?react';
import DateIcon from '@/components/Icon/svgs/date.svg?react';
import locationIcon from '@/components/Icon/svgs/location.svg?react';
import CheckmarkIcon from '@/components/Icon/svgs/checkmark.svg?react';
import CheckboxVIcon from '@/components/Icon/svgs/checkboxV.svg?react';
import ShowIcon from '@/components/Icon/svgs/show.svg?react';
import HideIcon from '@/components/Icon/svgs/hide.svg?react';
import PlusIcon from '@/components/Icon/svgs/plus.svg?react';
import CardsIcon from '@/components/Icon/svgs/cards.svg?react';
import DownloadIcon from '@/components/Icon/svgs/download.svg?react';
import EmailIcon from '@/components/Icon/svgs/email.svg?react';
import PrivateIcon from '@/components/Icon/svgs/private.svg?react';
import PublicIcon from '@/components/Icon/svgs/public.svg?react';
import AfterParagraphIcon from '@/components/Icon/svgs/after-paragraph.svg?react';
import TextJustifyIcon from '@/components/Icon/svgs/justify.svg?react';
import TextLeftIcon from '@/components/Icon/svgs/text-left.svg?react';
import TextRightIcon from '@/components/Icon/svgs/text-right.svg?react';
import TextCenterIcon from '@/components/Icon/svgs/text-center.svg?react';
import CollapseIcon from '@/components/Icon/svgs/collapse.svg?react';
import TableIcon from '@/components/Icon/svgs/table.svg?react';
import DividerIcon from '@/components/Icon/svgs/divider.svg?react';
import PageBreakIcon from '@/components/Icon/svgs/page-break.svg?react';
import ExcalidrawIcon from '@/components/Icon/svgs/excalidraw.svg?react';
import ColumnsLayoutIcon from '@/components/Icon/svgs/column-layout.svg?react';
import YoutubeIcon from '@/components/Icon/svgs/youtube.svg?react';
import TwitterIcon from '@/components/Icon/svgs/tweet.svg?react';
import ChatIcon from '@/components/Icon/svgs/chat.svg?react';
import ChatCircleIcon from '@/components/Icon/svgs/chat-circle.svg?react';
import HistoryIcon from '@/components/Icon/svgs/history.svg?react';
import FileIcon from '@/components/Icon/svgs/file.svg?react';
import GlobeIcon from '@/components/Icon/svgs/globe.svg?react';
import CheckIcon from '@/components/Icon/svgs/check.svg?react';
import XIcon from '@/components/Icon/svgs/x-thin.svg?react';
import SendIcon from '@/components/Icon/svgs/send.svg?react';
import SearchIcon from '@/components/Icon/svgs/search-icon.svg?react';
import AttachmentIcon from '@/components/Icon/svgs/attachment.svg?react';
import TagIcon from '@/components/Icon/svgs/tag.svg?react';
import PolygonIcon from '@/components/Icon/svgs/polygon.svg?react';
import LineIcon from '@/components/Icon/svgs/line.svg?react';
import NewThreadIcon from '@/components/Icon/svgs/newThread.svg?react';
import SaveIcon from '@/components/Icon/svgs/save.svg?react';
import ExportToPageIcon from '@/components/Icon/svgs/export-to-page.svg?react';
import ExpandIcon from '@/components/Icon/svgs/expand.svg?react';
import ArrowsInIcon from '@/components/Icon/svgs/arrows-in.svg?react';
import ArrowRightIcon from '@/components/Icon/svgs/arrow-right.svg?react';
import ArrowRightDoubleIcon from '@/components/Icon/svgs/arrow-right-double.svg?react';
import MinimizeIcon from '@/components/Icon/svgs/minimize.svg?react';
import AddToFavoriteIcon from '@/components/Icon/svgs/add-to-favorites.svg?react';
import UnstarIcon from '@/components/Icon/svgs/unstar.svg?react';
import EditIcon from '@/components/Icon/svgs/edit.svg?react';
import SortAscendingIcon from '@/components/Icon/svgs/sort-ascending.svg?react';
import SortDescendingIcon from '@/components/Icon/svgs/sort-descending.svg?react';
import SortNoneIcon from '@/components/Icon/svgs/sort-none.svg?react';
import NewFolderIcon from '@/components/Icon/svgs/new-folder.svg?react';
import FolderIcon from '@/components/Icon/svgs/folder.svg?react';
import FolderOpenIcon from '@/components/Icon/svgs/folder-open.svg?react';
import PdfIcon from '@/components/Icon/svgs/pdf.svg?react';
import BoldIcon from '@/components/Icon/svgs/bold.svg?react';
import ItalicIcon from '@/components/Icon/svgs/italic.svg?react';
import UnderlineIcon from '@/components/Icon/svgs/underline.svg?react';
import LinkIcon from '@/components/Icon/svgs/link.svg?react';
import LockLockedIcon from '@/components/Icon/svgs/lock-locked.svg?react';
import LockUnlockedIcon from '@/components/Icon/svgs/lock-unlocked.svg?react';
import LightingIcon from '@/components/Icon/svgs/lightning.svg?react';
import SparkleIcon from '@/components/Icon/svgs/sparkle.svg?react';
import MapIcon from '@/components/Icon/svgs/bsMap.svg?react';
import PellesStarIcon from '@/components/Icon/svgs/pelles-star-full.svg?react';
import PushPinIcon from '@/components/Icon/svgs/push-pin.svg?react';
import MagicWandIcon from '@/components/Icon/svgs/magic-wand.svg?react';
import CountingIcon from '@/components/Icon/svgs/counting.svg?react';
import ChecklistsIcon from '@/components/Icon/svgs/checklists.svg?react';
import BsIconCircleIcon from '@/components/Icon/svgs/bsInfoCircle.svg?react';
import UploadIcon from '@/components/Icon/svgs/upload.svg?react';
import OkbPellesLogoIcon from '@/components/Icon/svgs/oKB-pelles-logo.svg?react';
import OkbPellesLogoNoStrokeIcon from '@/components/Icon/svgs/oKB-Pelles-logo-no-stroke.svg?react';
import BsShareIcon from '@/components/Icon/svgs/bsShare.svg?react';
import BsPlusLargeIcon from '@/components/Icon/svgs/bsPlusLg.svg?react';
import BsStarIcon from '@/components/Icon/svgs/bsStar.svg?react';
import InformationIcon from '@/components/Icon/svgs/information.svg?react';
import PaintBrushIcon from '@/components/Icon/svgs/paint-brush.svg?react';
import ReadingIcon from '@/components/Icon/svgs/reading.svg?react';
import ParagraphAdjustmentIcon from '@/components/Icon/svgs/paragraph-adjustment.svg?react';
import WrenchIcon from '@/components/Icon/svgs/wrench.svg?react';
import PreviewIcon from '@/components/Icon/svgs/preview.svg?react';
import VersionIcon from '@/components/Icon/svgs/version.svg?react';
import PdfGrayIcon from '@/components/Icon/svgs/pdf-gray.svg?react';
import DeleteIcon from '@/components/Icon/svgs/delete-x.svg?react';
import FilesIcon from '@/components/Icon/svgs/files.svg?react';
import AppsIcon from '@/components/Icon/svgs/apps.svg?react';
import RecentlyViewedIcon from '@/components/Icon/svgs/recently-viewed.svg?react';
import CompareIcon from '@/components/Icon/svgs/compare.svg?react';
import ConnectivityIcon from '@/components/Icon/svgs/connectivity.svg?react';
import ArrowBoldIcon from '@/components/Icon/svgs/arrow-bold.svg?react';
import PlayIcon from '@/components/Icon/svgs/play.svg?react';
import ProjectIcon from '@/components/Icon/svgs/project.svg?react';
import GradiantDoubleCheckIcon from '@/components/Icon/svgs/double-check.svg?react';
import GradiantKBIcon from '@/components/Icon/svgs/org-knowledge-base.svg?react';
import GradiantCreateIcon from '@/components/Icon/svgs/gradient-create.svg?react';
import CreateIcon from '@/components/Icon/svgs/create.svg?react';
import GradiantCountAndMeasureIcon from '@/components/Icon/svgs/count-and-measure.svg?react';
import SetupIcon from '@/components/Icon/svgs/setup.svg?react';
import ResultsIcon from '@/components/Icon/svgs/results.svg?react';
import Hide2Icon from '@/components/Icon/svgs/hide-2.svg?react';
import Show3Icon from '@/components/Icon/svgs/show-3.svg?react';
import PhoneIcon from '@/components/Icon/svgs/phone.svg?react';
import CheckRingLightIcon from '@/components/Icon/svgs/check_ring_light.svg?react';
import UnCheckSquareIcon from '@/components/Icon/svgs/uncheck-square.svg?react';
import CheckSquare1Icon from '@/components/Icon/svgs/check-square-1.svg?react';
import GradiantCompareIcon from '@/components/Icon/svgs/gradiantCompare.svg?react';
import OrgKbIcon from '@/components/Icon/svgs/orgKnowledgeBase.svg?react';
import ListIcon from '@/components/Icon/svgs/list.svg?react';
import ViewIcon from '@/components/Icon/svgs/view.svg?react';
import FocusIcon from '@/components/Icon/svgs/focus.svg?react';
import MentionIcon from '@/components/Icon/svgs/mention.svg?react';
import EditWithUnderlineIcon from '@/components/Icon/svgs/edit-with-underline.svg?react';
import moveToIcon from '@/components/Icon/svgs/move-to.svg?react';
import ComputerIcon from '@/components/Icon/svgs/computer.svg?react';
import RerunIcon from '@/components/Icon/svgs/rerun.svg?react';
import PullIcon from '@/components/Icon/svgs/pull.svg?react';
import DoubleArrowLeftIcon from '@/components/Icon/svgs/double-arrow-right.svg?react';
import BulbIcon from '@/components/Icon/svgs/bulb.svg?react';
import TargetIcon from '@/components/Icon/svgs/target.svg?react';
import SpaceshipIcon from '@/components/Icon/svgs/spaceship.svg?react';
import QuestionModeIcon from '@/components/Icon/svgs/question_mode.svg?react';
import DocumentUploadsIcon from '@/components/Icon/svgs/document-uploads.svg?react';
import DislikeIcon from '@/components/Icon/svgs/dislike.svg?react';
import LikeIcon from '@/components/Icon/svgs/like.svg?react';
import TreeDotsVerticalIcon from '@/components/Icon/svgs/tree-dots-vertical.svg?react';
import UncheckedRingIcon from '@/components/Icon/svgs/unchecked-ring.svg?react';
import CheckedRingIcon from '@/components/Icon/svgs/checked-ring.svg?react';
import DrillIcon from '@/components/Icon/svgs/drill.svg?react';
import MoveDotsIcon from '@/components/Icon/svgs/move-dots.svg?react';
import QuestionIcon from '@/components/Icon/svgs/question.svg?react';
import NavArrowIcon from '@/components/Icon/svgs/nav-arrow.svg?react';
import RunIcon from '@/components/Icon/svgs/run.svg?react';
import ForwardIcon from '@/components/Icon/svgs/forward.svg?react';
import HammerIcon from '@/components/Icon/svgs/hammer.svg?react';
import AiDocsIcon from '@/components/Icon/svgs/ai-docs.svg?react';
import BackArrowIcon from '@/components/Icon/svgs/back-arrow.svg?react';
import AcademyIcon from '@/components/Icon/svgs/academy.svg?react';
import ToolsIcon from '@/components/Icon/svgs/tools.svg?react';
import WeightsIcon from '@/components/Icon/svgs/weights.svg?react';
import ShortParagraphAiIcon from '@/components/Icon/svgs/short-paragraph-ai.svg?react';
import ParagraphAiIcon from '@/components/Icon/svgs/paragraph-ai.svg?react';
import InlineAiIcon from '@/components/Icon/svgs/inline-ai.svg?react';
import UsersIcon from '@/components/Icon/svgs/users.svg?react';
import UndoIcon from '@/components/Icon/svgs/undo.svg?react';
import TextColorIcon from '@/components/Icon/svgs/text-color.svg?react';
import FontSizeIcon from '@/components/Icon/svgs/font-size.svg?react';
import ColorIcon from '@/components/Icon/svgs/color.svg?react';
import DrumRideIcon from '@/components/Icon/svgs/drum-ride.svg?react';
import WorkerIcon from '@/components/Icon/svgs/worker.svg?react';
import CraneIcon from '@/components/Icon/svgs/crane.svg?react';
import DirtTruckIcon from '@/components/Icon/svgs/dirt-truck.svg?react';
import ConeIcon from '@/components/Icon/svgs/cone.svg?react';
import WestIcon from '@/components/Icon/svgs/west.svg?react';
import SledgeHammerIcon from '@/components/Icon/svgs/sledge-hammer.svg?react';
import BlockIcon from '@/components/Icon/svgs/block.svg?react';
import WreckingBallIcon from '@/components/Icon/svgs/wrecking-ball.svg?react';
import EvotedIcon from '@/components/Icon/svgs/evoted.svg?react';
import FiIcon from '@/components/Icon/svgs/fi.svg?react';
import ScrewdriverIcon from '@/components/Icon/svgs/screwdriver.svg?react';
import MiniCraneIcon from '@/components/Icon/svgs/mini-crane.svg?react';
import ScrewIcon from '@/components/Icon/svgs/screw.svg?react';
import PipeIcon from '@/components/Icon/svgs/pipe.svg?react';
import HVACIcon from '@/components/Icon/svgs/hvac.svg?react';
import ElectricityIcon from '@/components/Icon/svgs/electricity.svg?react';
import WeLoveUriIcon from '@/components/Icon/svgs/we-love-uri.svg?react';
import AlignCenterIcon from '@/components/Icon/svgs/align-center.svg?react';
import AlignLeftIcon from '@/components/Icon/svgs/align-left.svg?react';
import AlignRightIcon from '@/components/Icon/svgs/align-right.svg?react';
import AlignJustifyIcon from '@/components/Icon/svgs/align-justify.svg?react';
import ImageIcon from '@/components/Icon/svgs/image.svg?react';
import CollapseContentIcon from '@/components/Icon/svgs/collapse-content.svg?react';
import HorizontalRuleIcon from '@/components/Icon/svgs/horizontal-rule.svg?react';
import SettingsIcon from '@/components/Icon/svgs/settings.svg?react';
import BlankPageIcon from '@/components/Icon/svgs/blank-page.svg?react';
import AiSearchIcon from '@/components/Icon/svgs/ai-search.svg?react';
import TrashIcon from '@/components/Icon/svgs/trash.svg?react';
import SearchCheck2Icon from '@/components/Icon/svgs/search-check-2.svg?react';

export interface IconProps extends Omit<SvgIconProps, 'component'> {
  name: IconNames;
}

export type IconNames = keyof typeof icons;

const icons = {
  ai: AiIcon,
  arrowDown: ArrowDownIcon,
  bulletList: BulletListIcon,
  code: CodeIcon,
  smallChecklist: SmallChecklistIcon,
  h1: H1Icon,
  h2: H2Icon,
  h3: H3Icon,
  numberedList: NumberedListIcon,
  quote: QuoteIcon,
  text: TextIcon,
  checkmark: CheckmarkIcon,
  show: ShowIcon,
  hide: HideIcon,
  plus: PlusIcon,
  cards: CardsIcon,
  download: DownloadIcon,
  email: EmailIcon,
  private: PrivateIcon,
  public: PublicIcon,
  afterParagraph: AfterParagraphIcon,
  justify: TextJustifyIcon,
  left: TextLeftIcon,
  right: TextRightIcon,
  center: TextCenterIcon,
  collapse: CollapseIcon,
  table: TableIcon,
  divider: DividerIcon,
  pageBreak: PageBreakIcon,
  excalidraw: ExcalidrawIcon,
  columnsLayout: ColumnsLayoutIcon,
  youtube: YoutubeIcon,
  twitter: TwitterIcon,
  chat: ChatIcon,
  history: HistoryIcon,
  file: FileIcon,
  globe: GlobeIcon,
  check: CheckIcon,
  x: XIcon,
  send: SendIcon,
  search: SearchIcon,
  attachment: AttachmentIcon,
  tag: TagIcon,
  polygon: PolygonIcon,
  line: LineIcon,
  checked: CheckboxVIcon,
  newThread: NewThreadIcon,
  exportToPage: ExportToPageIcon,
  save: SaveIcon,
  expand: ExpandIcon,
  arrowsIn: ArrowsInIcon,
  arrowRight: ArrowRightIcon,
  arrowRightDouble: ArrowRightDoubleIcon,
  sortAscending: SortAscendingIcon,
  sortDescending: SortDescendingIcon,
  sortNone: SortNoneIcon,
  minimize: MinimizeIcon,
  addToFavorite: AddToFavoriteIcon,
  unstar: UnstarIcon,
  edit: EditIcon,
  newFolder: NewFolderIcon,
  folder: FolderIcon,
  folderOpen: FolderOpenIcon,
  pdf: PdfIcon,
  pdfGray: PdfGrayIcon,
  bold: BoldIcon,
  italic: ItalicIcon,
  underline: UnderlineIcon,
  link: LinkIcon,
  lockLocked: LockLockedIcon,
  lockUnlocked: LockUnlockedIcon,
  lighting: LightingIcon,
  sparkle: SparkleIcon,
  map: MapIcon,
  pellesStar: PellesStarIcon,
  pushPin: PushPinIcon,
  magicWand: MagicWandIcon,
  counting: CountingIcon,
  list: ListIcon,
  checklists: ChecklistsIcon,
  bsIconCircle: BsIconCircleIcon,
  upload: UploadIcon,
  okbPellesLogo: OkbPellesLogoIcon,
  bsShare: BsShareIcon,
  bsPlusLarge: BsPlusLargeIcon,
  bsStar: BsStarIcon,
  oKbLogoNoStroke: OkbPellesLogoNoStrokeIcon,
  information: InformationIcon,
  paintBrush: PaintBrushIcon,
  paragraphAdjustment: ParagraphAdjustmentIcon,
  reading: ReadingIcon,
  files: FilesIcon,
  apps: AppsIcon,
  viewed: RecentlyViewedIcon,
  compare: CompareIcon,
  connectivity: ConnectivityIcon,
  arrowBold: ArrowBoldIcon,
  play: PlayIcon,
  project: ProjectIcon,
  wrench: WrenchIcon,
  preview: PreviewIcon,
  version: VersionIcon,
  phone: PhoneIcon,
  delete: DeleteIcon,
  gradiantDoubleCheck: GradiantDoubleCheckIcon,
  gradiantKB: GradiantKBIcon,
  gradiantCreate: GradiantCreateIcon,
  gradiantCountAndMeasureIcon: GradiantCountAndMeasureIcon,
  setup: SetupIcon,
  results: ResultsIcon,
  hide2: Hide2Icon,
  show3: Show3Icon,
  verified: CheckRingLightIcon,
  unCheckSquare: UnCheckSquareIcon,
  checkSquare1: CheckSquare1Icon,
  gradiantCompare: GradiantCompareIcon,
  orgKb: OrgKbIcon,
  view: ViewIcon,
  focus: FocusIcon,
  mention: MentionIcon,
  editWithUnderline: EditWithUnderlineIcon,
  moveTo: moveToIcon,
  computer: ComputerIcon,
  rerun: RerunIcon,
  pull: PullIcon,
  doubleArrowLeft: DoubleArrowLeftIcon,
  bulb: BulbIcon,
  target: TargetIcon,
  spaceship: SpaceshipIcon,
  questionMode: QuestionModeIcon,
  documentUploads: DocumentUploadsIcon,
  dislike: DislikeIcon,
  like: LikeIcon,
  treeDotsVertical: TreeDotsVerticalIcon,
  checkedRing: CheckedRingIcon,
  uncheckedRing: UncheckedRingIcon,
  drill: DrillIcon,
  moveDots: MoveDotsIcon,
  question: QuestionIcon,
  navArrow: NavArrowIcon,
  run: RunIcon,
  forward: ForwardIcon,
  hammer: HammerIcon,
  chatCircle: ChatCircleIcon,
  aiDocs: AiDocsIcon,
  backArrow: BackArrowIcon,
  academy: AcademyIcon,
  tools: ToolsIcon,
  weights: WeightsIcon,
  textT: TextTIcon,
  date: DateIcon,
  location: locationIcon,
  create: CreateIcon,
  shortParagraphAi: ShortParagraphAiIcon,
  paragraphAi: ParagraphAiIcon,
  inlineAi: InlineAiIcon,
  drumRide: DrumRideIcon,
  worker: WorkerIcon,
  crane: CraneIcon,
  dirtTruck: DirtTruckIcon,
  cone: ConeIcon,
  west: WestIcon,
  sledgeHammer: SledgeHammerIcon,
  block: BlockIcon,
  wreckingBall: WreckingBallIcon,
  evoted: EvotedIcon,
  fi: FiIcon,
  screwdriver: ScrewdriverIcon,
  miniCrane: MiniCraneIcon,
  screw: ScrewIcon,
  pipe: PipeIcon,
  hvac: HVACIcon,
  electricity: ElectricityIcon,
  weLoveUri: WeLoveUriIcon,
  users: UsersIcon,
  undo: UndoIcon,
  textColor: TextColorIcon,
  fontSize: FontSizeIcon,
  color: ColorIcon,
  alignCenter: AlignCenterIcon,
  alignLeft: AlignLeftIcon,
  alignRight: AlignRightIcon,
  alignJustify: AlignJustifyIcon,
  image: ImageIcon,
  collapseContent: CollapseContentIcon,
  horizontalRule: HorizontalRuleIcon,
  settings: SettingsIcon,
  blankPage: BlankPageIcon,
  aiSearch: AiSearchIcon,
  trash: TrashIcon,
  searchCheck2: SearchCheck2Icon,
};

const Icon: FC<IconProps> = ({ name, sx, ...props }) => (
  <SvgIcon component={icons[name]} inheritViewBox {...props} sx={{ ...sx, fill: 'none' }} />
);

export default Icon;
