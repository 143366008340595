import { FC, MouseEvent, KeyboardEvent, useEffect, useState } from 'react';
import { NodeRendererProps } from 'react-arborist';
import { Box, CircularProgress, IconButton, Input, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { BsThreeDots } from 'react-icons/bs';
import { Key } from 'ts-key-enum';
import { MenuItemProps } from '@/components/MoreActions/MoreActions';
import Actions from '@/views/Projects/components/Actions/Actions';
import { TreePage } from '@/components/Pages/types';
import Icon from '@/components/Icon/Icon';
import SidebarGroupItem from '@/components/Sidebar/components/SidebarGroupItem';

interface TreePageNodeProps extends NodeRendererProps<TreePage> {
  isActive: boolean;
  onNameClick: (page: TreePage) => void;
}

const TreePageNode: FC<TreePageNodeProps> = ({ isActive, node, style, dragHandle, tree, onNameClick }) => {
  const { t } = useTranslation('lexicalEditor');
  const { palette } = useTheme();
  const actionsState = usePopupState({
    variant: 'popover',
    popupId: `tree-page-actions-${node.data.item.id}`,
  });
  const [pageName, setPageName] = useState(node.data.item.name);
  const isReady = !!node.data.item.page?.isReady;

  useEffect(() => {
    if (!node.isEditing) return;

    setPageName(node.data.item.name);
  }, [node.isEditing]);

  const onDelete = (event: MouseEvent) => {
    event.stopPropagation();
    tree.delete(node);
  };

  const onEdit = (event: MouseEvent) => {
    event.stopPropagation();
    tree.edit(node);
  };

  const onAdd = (event: MouseEvent) => {
    event.stopPropagation();
    tree.create({ parentId: node.id });
  };

  const onRootClick = (event: MouseEvent) => {
    if (node.data.item.isFolder) {
      event.stopPropagation();
      node.deselect();
    }
  };

  const onOpenClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (node.data.item.isFolder) {
      node.toggle();
      node.deselect();
    } else {
      onNameClick(node.data);
    }
  };

  const onToggleClick = (event: MouseEvent) => {
    event.stopPropagation();
    node.toggle();
  };

  const save = () => pageName.trim() && node.submit(pageName);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.code == Key.Enter) save();
  };

  const onBlur = () => {
    save();
  };

  const menuItems: MenuItemProps[] = [
    { id: 'actions.delete', children: t('actions.delete'), onClick: onDelete },
    { id: 'actions.edit', children: t('actions.rename'), onClick: onEdit },
  ].filter(item => !!item);

  const renderPublicPrivate = () => (
    <Box sx={{ mr: '2px', height: 16 }}>
      {node.data.item.page?.isPrivate ? <Box sx={{ flexShrink: 0, width: 16 }} /> : <Icon name="users" fontSize="small" />}
    </Box>
  );

  const renderPageIcon = () => {
    if (!node.data.item.isFolder && !node.children?.length) return null;

    return (
      <IconButton color="secondary" size="small" sx={{ p: 0.3, backgroundColor: 'transparent' }} onClick={onToggleClick}>
        <Icon name={node.isOpen ? 'folderOpen' : 'folder'} fontSize="small" />
      </IconButton>
    );
  };

  const renderLeft = () => {
    if (!isReady) return <CircularProgress size={16} sx={{ flexShrink: 0 }} />;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.3, pl: 0.3 }}>
        {renderPublicPrivate()}
        {renderPageIcon()}
      </Box>
    );
  };

  const renderTitle = () => {
    if (node.isEditing) {
      return (
        <Input
          autoFocus
          sx={{ height: 17, fontSize: 'body2.fontSize' }}
          value={pageName}
          onChange={event => setPageName(event.target.value)}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      );
    }

    return (
      <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={onOpenClick}>
        {node.data.item.name}
      </Box>
    );
  };

  const renderActions = () => {
    if (!isReady) {
      return (
        <IconButton color="secondary" size="small" sx={{ p: 0, backgroundColor: 'transparent' }} onClick={onDelete}>
          <Icon name="trash" fontSize="small" />
        </IconButton>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Actions
          sx={{ p: 0 }}
          icon={<BsThreeDots color={palette.secondary.dark} size={16} />}
          menuItems={menuItems}
          actionsState={actionsState}
        />

        <Tooltip arrow disableInteractive title={t('drawer.addPage')}>
          <IconButton color="secondary" size="small" sx={{ p: 0, backgroundColor: 'transparent' }} onClick={onAdd}>
            <Icon name="plus" fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <SidebarGroupItem
      ref={dragHandle}
      style={style}
      sx={{
        borderTop: node.isDragging ? '1px dashed' : 'none',
        borderBottom: node.isDragging ? '1px dashed' : 'none',
        borderRadius: 1,
      }}
      isActive={isActive}
      title={renderTitle()}
      left={renderLeft()}
      actions={renderActions()}
      onClick={onRootClick}
    />
  );
};

export default TreePageNode;
