import { FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Popover, Skeleton, useTheme } from '@mui/material';
import scrollIntoView from 'scroll-into-view-if-needed';
import Icon from '@/components/Icon/Icon';
import { bindPopover, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { $getNodeByKey, $setSelection, COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useTemplateContext } from '@/containers/PagesEditor/context/TemplateContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import RoundedMentionInput from '@/components/RoundedMentionInput/RoundedMentionInput';
import { $isInlineAiNode } from '@/containers/PagesEditor/nodes/InlineAiNode';
import nextTick from '@/services/nextTick';
import BlockDeleteButton from '@/containers/PagesEditor/components/BlockDeleteButton';

interface InlineAiBlockProps {
  nodeKey: string;
  initialPrompt?: string;
  initialOpenState?: true;
}

const InlineAiBlock: FC<InlineAiBlockProps> = ({ nodeKey, initialOpenState = false, initialPrompt = '' }) => {
  const { t } = useTranslation('lexicalEditor');
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey);
  const popupState = usePopupState({ variant: 'popover', popupId: `InlineAiBlock_${nodeKey}` });
  const { processStatus } = useTemplateContext();
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [prompt, setPrompt] = useState(initialPrompt);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);

  const handleRemove = useCallback(
    (event?: MouseEvent) => {
      event?.stopPropagation();
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isInlineAiNode(node)) {
          node.remove();
        }
      });
    },
    [editor, nodeKey],
  );

  useEffect(() => {
    if (!initialOpenState || !anchorEl) return;
    popupState.open();
  }, [initialOpenState, anchorEl]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          KEY_DELETE_COMMAND,
          () => {
            isSelected && handleRemove();
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          KEY_BACKSPACE_COMMAND,
          () => {
            isSelected && handleRemove();
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, isSelected, handleRemove, setSelected],
  );

  useEffect(() => {
    editor.update(() => $setSelection(null));
    scrollAnchorRef.current && scrollIntoView(scrollAnchorRef.current, { scrollMode: 'if-needed' });

    nextTick(() => {
      const textarea = inputRef.current;
      if (!textarea) return;

      textarea.focus();
      textarea.selectionStart = textarea.value.length;
    });
  }, [editor, popupState.isOpen]);

  const handlePromptChange = (nextPrompt: string) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isInlineAiNode(node)) {
        node.setPrompt(nextPrompt);
      }
    });
    setPrompt(nextPrompt);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    event.stopPropagation();
  };

  if (processStatus === 'ready') return null;
  if (processStatus === 'in-progress')
    return <Skeleton variant="rectangular" sx={{ display: 'inline-block', width: 100, height: 14, mx: 1, borderRadius: 1 }} />;

  return (
    <>
      <ButtonBase
        sx={{
          justifyContent: 'left',
          gap: 0.5,
          maxWidth: 150,
          height: 24,
          px: 0.5,
          border: '1px solid',
          borderColor: palette.primary.light,
          borderRadius: 1,
          '&:hover': {
            '.InlineAiBlock__icon': { display: 'none' },
            '.InlineAiBlock__icon-hover': { display: 'inline' },
            '.BlockDeleteButton': { display: 'inline-flex' },
          },
          '.BlockDeleteButton': isSelected ? { display: 'inline-flex' } : undefined,
        }}
        {...bindToggle(popupState)}
        ref={element => element && setAnchorEl(element)}
      >
        <Icon className="InlineAiBlock__icon" name="inlineAi" fontSize="small" htmlColor={palette.primary.dark} />
        <Icon
          className="InlineAiBlock__icon-hover"
          sx={{ display: 'none' }}
          name="editWithUnderline"
          fontSize="small"
          htmlColor={palette.primary.dark}
        />
        {prompt && <Box sx={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{prompt}</Box>}
        <BlockDeleteButton onClick={handleRemove} />
        <Box ref={scrollAnchorRef} sx={{ position: 'absolute', bottom: -40 }} />
      </ButtonBase>

      <Popover
        {...bindPopover(popupState)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -5, horizontal: 'left' }}
        slotProps={{
          paper: {
            sx: { width: 300, border: '1px solid', borderColor: palette.primary.light, borderRadius: 2, boxShadow: 0 },
          },
        }}
      >
        <RoundedMentionInput
          placeholder={t('editor.ai.inlinePromptPlaceholder')}
          ref={inputRef}
          value={prompt}
          ContainerProps={{ sx: { borderRadius: 0, border: 0, background: 'transparent', boxShadow: 'none' } }}
          style={{ width: '100%' }}
          featureName="create"
          // @ts-expect-error
          onKeyDown={handleKeyDown}
          onChange={(_, nextPrompt) => handlePromptChange(nextPrompt)}
        />
      </Popover>
    </>
  );
};

export default InlineAiBlock;
