import { FC, ReactNode } from 'react';
import Icon from '@/components/Icon/Icon';
import { IconButton, Menu } from '@mui/material';
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { bindMenu } from 'material-ui-popup-state';

interface PlusButtonMenuProps {
  popupId: string;
  children: ReactNode;
}

const PlusButtonMenu: FC<PlusButtonMenuProps> = ({ popupId, children }) => {
  const popupState = usePopupState({ variant: 'popover', popupId });

  return (
    <>
      <IconButton color="primary" size="small" {...bindToggle(popupState)}>
        <Icon name="plus" />
      </IconButton>
      <Menu {...bindMenu(popupState)} onClick={() => popupState.close()}>
        {children}
      </Menu>
    </>
  );
};

export default PlusButtonMenu;
