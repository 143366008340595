import { Button, IconButton, Stack } from '@mui/material';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { Key } from 'ts-key-enum';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChecklistFormValue } from '@/views/Project/components/ChecklistsManager/ChecklistsManagerDialog';
import TextInput from '@/components/TextInput/TextInput';
import Questions from '@/views/Project/components/ChecklistsManager/Questions';
import { Checklist, QueryQuestion, Tag } from '@/api/generated';
import Icon from '@/components/Icon/Icon';
import ChecklistAutocomplete from '@/views/Project/components/ChecklistsManager/ChecklistAutocomplete';
import SearchInput from '@/components/SearchInput';
import ActionButton from '@/components/ActionButton';
import { useDropzone } from 'react-dropzone';
import { acceptedFileTypes } from '@/utils/consts';
import TextInputWithEditButton from '@/components/TextInputWithEditButton';
import { ChecklistIcon, ChecklistIconNames } from './ChecklistIcon';

interface ChecklistFormProps {
  onSaveField: (data?: ChecklistFormValue) => Promise<void>;
  selectedIds: Set<string>;
  onQuestionToggle: (id?: string) => void;
  onToggleAll: () => void;
  selectedAllIds: boolean;
  onDeleteQuestions: () => void;
  onUpdateQueryTags: (newTag: Tag, queryToUpdateId: string) => Promise<void>;
  onDeleteTag: (tag: Tag, id?: string) => Promise<void>;
  checklists?: Checklist[];
  selectedQuestions?: QueryQuestion[];
  setCurrentChecklistId: (currentChecklistId?: string) => void;
  canEdit?: boolean;
  currentChecklist?: Checklist;
  onRun?: () => void;
  onGenerateQuestionsFromFile: (files: File[]) => void;
}

const ChecklistForm: FC<ChecklistFormProps> = ({
  onSaveField,
  selectedIds,
  onQuestionToggle,
  onToggleAll,
  selectedAllIds,
  onDeleteQuestions,
  onUpdateQueryTags,
  onDeleteTag,
  checklists,
  selectedQuestions,
  setCurrentChecklistId,
  canEdit,
  currentChecklist,
  onRun,
  onGenerateQuestionsFromFile,
}) => {
  const { t } = useTranslation('project');

  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [isEditChecklistTitle, setIsEditChecklistTitle] = useState(false);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(!!currentChecklist?.description);
  const { control, setFocus, handleSubmit, getFieldState, trigger, watch, clearErrors } = useFormContext<ChecklistFormValue>();
  const description = watch('description');

  const isEditMode = (canEdit && isEditChecklistTitle) || !currentChecklist?._id;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onGenerateQuestionsFromFile,
    disabled: !currentChecklist?.is_ready,
    multiple: false,
    accept: acceptedFileTypes,
  });

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  useEffect(() => {
    setIsEditChecklistTitle(false);
    setIsInstructionsOpen(!!currentChecklist?.description);
  }, [currentChecklist?._id]);

  useEffect(() => {
    isEditChecklistTitle && setFocus('name');
  }, [isEditChecklistTitle, setFocus]);

  const renderActionForSelected = () => (
    <>
      <ChecklistAutocomplete
        onlyDuplicate={!canEdit}
        selectedQuestions={selectedQuestions}
        checklists={checklists}
        setCurrentChecklistId={setCurrentChecklistId}
        currentChecklist={currentChecklist}
        selectedIds={selectedIds}
      />

      {canEdit && (
        <IconButton sx={{ p: 0.5 }} onClick={onDeleteQuestions}>
          <Icon name="trash" fontSize="medium" />
        </IconButton>
      )}
    </>
  );

  const saveName = async () => {
    await trigger('name');
    if (getFieldState('name').invalid) return;

    onSaveField();
    setIsEditChecklistTitle(false);
  };

  const handleBlur = () => saveName();

  const handleNameKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();
    saveName();
  };

  return (
    <Stack gap={3} sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" gap={1}>
        {currentChecklist?.icon && !isEditMode && <ChecklistIcon name={currentChecklist?.icon as ChecklistIconNames} />}
        <TextInputWithEditButton
          isOpened={isEditMode}
          isEditable={canEdit && currentChecklist?.is_ready}
          autoFocus
          required
          label={t('queries.checklistsEditDialog.checklistLabel')}
          control={control}
          id="name"
          name="name"
          translate="project"
          onBlur={handleBlur}
          onKeyDown={handleNameKeyDown}
          onOpenClick={() => setIsEditChecklistTitle(true)}
          containerSx={{ flex: 1 }}
        />
      </Stack>
      <Stack gap={1.5} sx={{ minHeight: 0 }}>
        {currentChecklist?._id && (
          <>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
              <Button
                variant="outlined"
                color={!isInstructionsOpen && description ? 'primary' : 'secondary'}
                size="2xsmall"
                sx={{ gap: 1 }}
                onClick={() => setIsInstructionsOpen(prev => !prev)}
              >
                <Icon name="editWithUnderline" fontSize="small" />
                {t('queries.checklistsEditDialog.instructions.toggler')}
                <Icon name="arrowDown" fontSize="small" sx={{ transform: `rotate(${isInstructionsOpen ? '0deg' : '-90deg'})` }} />
              </Button>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                {!!selectedIds.size && renderActionForSelected()}

                {isSearchOpened ? (
                  <Controller
                    control={control}
                    name="searchQuestionValue"
                    render={({ field }) => (
                      <SearchInput sx={{ width: 200 }} autoFocus {...field} onBlur={() => setIsSearchOpened(false)} />
                    )}
                  />
                ) : (
                  <ActionButton onClick={() => setIsSearchOpened(true)}>
                    <Icon name="search" fontSize="large" sx={{ fill: 'text.primary' }} />
                  </ActionButton>
                )}

                <Button
                  size="2xsmall"
                  variant="contained"
                  startIcon={<Icon name="ai" sx={{ width: 16, height: 16 }} />}
                  sx={{ px: 1.5, borderRadius: 999 }}
                  disabled={!currentChecklist?.is_ready}
                  {...getRootProps()}
                >
                  {t(`queries.checklistsEditDialog.generateQuestionsFromFile`)}
                  <input {...getInputProps()} hidden />
                </Button>

                <Button
                  sx={{
                    gap: 1,
                    alignItems: 'center',
                    px: 1.5,
                    borderRadius: 999,
                  }}
                  disabled={!currentChecklist.queries?.length}
                  color="primary"
                  size="2xsmall"
                  variant="contained"
                  onClick={onRun}
                >
                  <Icon name="run" sx={{ width: 16, height: 16 }} />
                  {t(`queries.checklistsEditDialog.runChecklist`)}
                </Button>
              </Stack>
            </Stack>

            {isInstructionsOpen && (
              <TextInput
                autoComplete="off"
                multiline
                placeholder={t('queries.checklistsEditDialog.instructions.placeholder')}
                sx={{ width: '100%' }}
                disabled={!canEdit}
                control={control}
                id="ChecklistForm__description"
                name="description"
                size="small"
                onBlur={handleSubmit(onSaveField)}
              />
            )}

            <Questions
              canEdit={canEdit && currentChecklist?.is_ready}
              control={control}
              setFocus={setFocus}
              onSaveField={onSaveField}
              selectedIds={selectedIds}
              onQuestionToggle={onQuestionToggle}
              onToggleAll={onToggleAll}
              selectedAllIds={selectedAllIds}
              onUpdateQueryTags={onUpdateQueryTags}
              onDeleteTag={onDeleteTag}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ChecklistForm;
