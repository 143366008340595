import { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreateHandler, DeleteHandler, MoveHandler, NodeRendererProps, RenameHandler, Tree, TreeApi } from 'react-arborist';
import TreePageNode from '@/components/Pages/components/TreePageNode';
import { type TreePage } from '@/components/Pages/types';
import { PageMetadata } from '@/api/generated';
import SidebarGroupItem from '@/components/Sidebar/components/SidebarGroupItem';
import SidebarGroup from '@/components/Sidebar/components/SidebarGroup';
import UserAvatar from '@/components/Avatar/UserAvatar';
import Sidebar from '@/components/Sidebar/Sidebar';

interface PagesDrawerProps {
  slug: string;
  currentPageId?: string | null;
  sharedPages?: PageMetadata[];
  pagesTree?: TreePage[];
  onPage: (pageId: string) => void;
  onMove: MoveHandler<TreePage>;
  onCreate: CreateHandler<TreePage>;
  onRename: RenameHandler<TreePage>;
  onDelete: DeleteHandler<TreePage>;
}

const ROW_HEIGHT = 33;

const PagesTree = forwardRef<TreeApi<TreePage> | undefined, PagesDrawerProps>(
  ({ currentPageId, sharedPages, pagesTree, onPage, onMove, onCreate, onRename, onDelete }, ref) => {
    const { t } = useTranslation('lexicalEditor');
    const treeRef = useRef<TreeApi<TreePage>>();
    const dndRootRef = useRef<HTMLDivElement | null>(null);
    const { spacing } = useTheme();

    const [searchTerm, setSearchTerm] = useState('');
    const [isParentMounted, setIsParentMounted] = useState(false);

    const [treeHeight, setTreeHeight] = useState((pagesTree?.length ?? 0) * ROW_HEIGHT);

    useImperativeHandle(ref, () => treeRef.current!);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setIsParentMounted(true);
      }, 0);
      return () => clearTimeout(timeout);
    }, []);

    useLayoutEffect(() => {
      const visibleNodesCount = (treeRef.current && treeRef.current?.visibleNodes?.length) ?? 0;
      setTreeHeight(Math.max(pagesTree?.length ?? 0, visibleNodesCount) * ROW_HEIGHT);
    }, [pagesTree?.length]);

    const handlePageClick = (pageId: string) => onPage(pageId);

    const handlePageChange = useCallback((node: TreePage) => onPage(node.item.id), [onPage]);

    const handleCreate: CreateHandler<TreePage> = (...params) => {
      setSearchTerm('');
      return onCreate(...params);
    };

    const handleToggle = () => {
      setTimeout(() => {
        const visibleNodesCount = (treeRef.current && treeRef.current?.visibleNodes?.length) ?? 0;
        setTreeHeight(visibleNodesCount * ROW_HEIGHT);
      });
    };

    const TreePage = useCallback(
      (nodeProps: NodeRendererProps<TreePage>) => (
        <TreePageNode {...nodeProps} isActive={currentPageId === nodeProps.node.id} onNameClick={handlePageChange} />
      ),
      [handlePageChange, currentPageId],
    );

    return (
      <Sidebar ref={dndRootRef} searchText={searchTerm} onSearchTextChange={setSearchTerm} isOpenedByDefault={false}>
        <Stack gap={1.25} sx={{ position: 'relative' }}>
          {isParentMounted && (
            <SidebarGroup title={t('myPages', { count: pagesTree?.length ?? 0 })}>
              <Box sx={{ ml: -2 }}>
                <Tree
                  ref={treeRef}
                  dndRootElement={dndRootRef.current}
                  width="auto"
                  height={treeHeight}
                  indent={parseInt(spacing(3))}
                  openByDefault={false}
                  searchTerm={searchTerm}
                  searchMatch={(node, term) => node.data.item.name.toLowerCase().includes(term.toLowerCase())}
                  rowHeight={ROW_HEIGHT}
                  data={pagesTree}
                  idAccessor={node => node.item.id}
                  childrenAccessor="children"
                  onMove={onMove}
                  onCreate={handleCreate}
                  onRename={onRename}
                  onDelete={onDelete}
                  onToggle={handleToggle}
                >
                  {TreePage}
                </Tree>
              </Box>
            </SidebarGroup>
          )}
          {!!sharedPages?.length && (
            <SidebarGroup title={t('sharedPages', { count: sharedPages.length })}>
              {sharedPages.map(page => (
                <SidebarGroupItem
                  key={page._id}
                  sx={{ ml: -2, pl: 4 }}
                  title={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: '100%', minWidth: 0 }}>
                      <Box sx={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>{page.name}</Box>
                      <UserAvatar size={16} userId={page.created_by!} />
                    </Box>
                  }
                  isActive={currentPageId === page._id}
                  onClick={() => handlePageClick(page._id!)}
                />
              ))}
            </SidebarGroup>
          )}
        </Stack>
      </Sidebar>
    );
  },
);

export default PagesTree;
