import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import BlocksGroup from '@/views/Templates/components/BlocksGroup';
import DraggableLexicalBlock from '@/containers/PagesEditor/ui/DraggableLexicalBlock';
import Icon from '@/components/Icon/Icon';

const DraggableBlocks: FC = () => {
  const { palette } = useTheme();
  const { t } = useTranslation('templates');

  return (
    <Stack sx={{ gap: 1.5, px: 2, py: 1 }}>
      <Box
        sx={{
          py: 1,
          fontSize: 'body1.fontSize',
          fontWeight: 700,
          borderBottom: '1px solid',
          borderColor: palette.grey[100],
          color: palette.accent.contrastText,
        }}
      >
        {t('draggableBlocks.title')}
      </Box>

      <BlocksGroup title={t('draggableBlocks.aiBlock.title')}>
        <DraggableLexicalBlock
          type="ai"
          settings={{ block: 'ai', answerType: 'short_block' }}
          icon={<Icon name="shortParagraphAi" fontSize="small" />}
          text={t('draggableBlocks.aiBlock.shortBlock')}
        />
        <DraggableLexicalBlock
          type="ai"
          settings={{ block: 'ai', answerType: 'long_block' }}
          icon={<Icon name="paragraphAi" fontSize="small" />}
          text={t('draggableBlocks.aiBlock.longBlock')}
        />
        <DraggableLexicalBlock
          type="ai"
          settings={{ block: 'ai', answerType: 'table' }}
          icon={<Icon name="table" fontSize="small" />}
          text={t('draggableBlocks.aiBlock.table')}
        />
        <DraggableLexicalBlock
          type="ai"
          settings={{ block: 'ai', isInline: true }}
          icon={<Icon name="inlineAi" fontSize="small" />}
          text={t('draggableBlocks.aiBlock.inlineBlock')}
        />
      </BlocksGroup>

      <BlocksGroup title={t('draggableBlocks.textBlocks.title')}>
        <DraggableLexicalBlock
          type="table"
          icon={<Icon name="table" fontSize="small" />}
          text={t('draggableBlocks.textBlocks.table')}
        />
        <DraggableLexicalBlock
          type="check"
          icon={<Icon name="smallChecklist" fontSize="small" />}
          text={t('draggableBlocks.textBlocks.checklist')}
        />
        <DraggableLexicalBlock
          type="bullet"
          icon={<Icon name="bulletList" fontSize="small" />}
          text={t('draggableBlocks.textBlocks.bullets')}
        />
        <DraggableLexicalBlock
          type="number"
          icon={<Icon name="numberedList" fontSize="small" />}
          text={t('draggableBlocks.textBlocks.numbered')}
        />
      </BlocksGroup>

      <BlocksGroup title={t('draggableBlocks.projectBlocks.title')}>
        <DraggableLexicalBlock
          type="variable"
          settings={{ block: 'variable', isInline: true, type: 'name' }}
          icon={<Icon name="textT" fontSize="small" />}
          text={t('draggableBlocks.projectBlocks.name')}
        />
        <DraggableLexicalBlock
          type="variable"
          settings={{ block: 'variable', isInline: true, type: 'address' }}
          icon={<Icon name="location" fontSize="small" />}
          text={t('draggableBlocks.projectBlocks.address')}
        />
        <DraggableLexicalBlock
          type="variable"
          settings={{ block: 'variable', isInline: true, type: 'date' }}
          icon={<Icon name="date" fontSize="small" />}
          text={t('draggableBlocks.projectBlocks.date')}
        />
      </BlocksGroup>
    </Stack>
  );
};

export default DraggableBlocks;
