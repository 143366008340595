import { FC } from 'react';
import { View } from '@react-pdf/renderer';
import { CollapsibleContainerNode } from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleContainerNode';
import { $isCollapsibleTitleNode } from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleTitleNode';
import { $isCollapsibleContentNode } from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleContentNode';
import LexicalNodePdfView from '@/components/LexicalPdfExport/components/LexicalNodePdfView';

interface CodeViewProps {
  node: CollapsibleContainerNode;
}

const CollapsibleView: FC<CodeViewProps> = ({ node }) => (
  <View style={{ gap: 10, padding: 14, border: 1, borderColor: '#eee', borderRadius: 8, backgroundColor: '#fcfcfc' }}>
    {node.getChildren().map(child => {
      if ($isCollapsibleTitleNode(child)) {
        return (
          <View key={child.getKey()} style={{ fontWeight: 'bold' }}>
            <LexicalNodePdfView nodes={child.getChildren()} />
          </View>
        );
      }

      if ($isCollapsibleContentNode(child)) return <LexicalNodePdfView key={child.getKey()} nodes={child.getChildren()} />;

      return null;
    })}
  </View>
);

export default CollapsibleView;
