import { FC, ReactNode } from 'react';
import { $isParagraphNode, $isTextNode, LexicalNode, TextNode, ElementFormatType } from 'lexical';
import { Link, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { $isHorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { $isTableNode } from '@lexical/table';
import { $isLinkNode, LinkNode } from '@lexical/link';
import { $isListNode } from '@lexical/list';
import { $isCodeNode } from '@lexical/code';
import { $isHeadingNode, $isQuoteNode } from '@lexical/rich-text';
import { convertStringStyleToObject } from '@/components/LexicalPdfExport/utils';
import { $isCollapsibleContainerNode } from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleContainerNode';
import { $isPageBreakNode } from '@lexical/playground/nodes/PageBreakNode';
import { $isExcalidrawNode } from '@/containers/PagesEditor/nodes/ExcalidrawNode';
import PdfTableView from '@/components/LexicalPdfExport/components/PdfTableView';
import ExcalidrawPdfImage from '@/components/LexicalPdfExport/components/ExcalidrawPdfImage';
import PdfListView from '@/components/LexicalPdfExport/components/PdfListView';
import DividerView from '@/components/LexicalPdfExport/components/DividerView';
import QuoteView from '@/components/LexicalPdfExport/components/QuoteView';
import HeadingView from '@/components/LexicalPdfExport/components/HeadingView';
import CodeView from '@/components/LexicalPdfExport/components/CodeView';
import CollapsibleView from '@/components/LexicalPdfExport/components/CollapsibleView';
import { $isLayoutContainerNode } from '@lexical/playground/nodes/LayoutContainerNode';
import LayoutView from '@/components/LexicalPdfExport/components/LayoutView';
import { $isAiContainerNode, AiContainerNode } from '@/containers/PagesEditor/nodes/AiContainerNode';
import { $isAiContentNode } from '@/containers/PagesEditor/nodes/AiContentNode';
import { $isInlineAiContainerNode, InlineAiContainerNode } from '@/containers/PagesEditor/nodes/InlineAiContainerNode';
import { $isInlineAiContentNode } from '@/containers/PagesEditor/nodes/InlineAiContentNode';

interface LexicalNodePdfViewProps {
  nodes: LexicalNode[];
}

type TextLikeNode = TextNode | LinkNode;
type InlineNode = TextNode | InlineAiContainerNode;

const PARAGRAPH_FORMAT_TYPE: Partial<Record<ElementFormatType, Style['textAlign']>> = {
  left: 'left',
  right: 'right',
  center: 'center',
  justify: 'justify',
};

const isInlineNode = (node: LexicalNode): node is InlineNode =>
  $isTextNode(node) || $isLinkNode(node) || $isInlineAiContainerNode(node);

const LexicalNodePdfView: FC<LexicalNodePdfViewProps> = ({ nodes }) => {
  // Array in the result array will contain text nodes only
  // It is added to make them inline.
  const nodeElements = nodes.reduce<Array<LexicalNode | Array<InlineNode>>>((acc, child) => {
    if (!isInlineNode(child)) return [...acc, child];

    if (!acc[acc.length - 1]) acc.push([]);

    const lastElement = acc[acc.length - 1];
    if (Array.isArray(lastElement)) {
      lastElement.push(child);
    } else {
      acc.push([child]);
    }

    return acc;
  }, []);

  const renderTextNode = (node: TextNode) => (
    <Text style={convertStringStyleToObject(node.getStyle())}>{node.getTextContent()}</Text>
  );

  const renderLinkNode = (node: LinkNode) => (
    <Link href={node.getURL() ?? undefined}>
      <Text>{node.getTextContent()}</Text>
    </Link>
  );

  const renderTextNodes = (textNodes: InlineNode[], key: string): ReactNode => {
    const flatNodes = textNodes.flatMap(node => {
      if ($isTextNode(node) || $isLinkNode(node)) return node;
      if ($isInlineAiContainerNode(node)) {
        const contentNode = node.getChildren().find($isInlineAiContentNode);
        return contentNode ? (contentNode.getChildren() as TextLikeNode[]) : [];
      }

      return [];
    });

    return (
      <Text key={key}>
        {flatNodes.map(node => {
          if ($isTextNode(node)) return renderTextNode(node);
          if ($isLinkNode(node)) return renderLinkNode(node);
          return null;
        })}
      </Text>
    );
  };

  const renderAiContainerNode = (node: AiContainerNode) => {
    const contentNode = node.getChildren().find($isAiContentNode);
    return contentNode ? <LexicalNodePdfView nodes={contentNode.getChildren()} /> : null;
  };

  const renderSingleElements = (node: LexicalNode): ReactNode => {
    if ($isPageBreakNode(node)) return <View break />;

    if ($isParagraphNode(node)) {
      return (
        <View style={{ minHeight: 20, paddingVertical: 2, textAlign: PARAGRAPH_FORMAT_TYPE[node.getFormatType()] ?? 'left' }}>
          <LexicalNodePdfView nodes={node.getChildren()} />
        </View>
      );
    }

    if ($isLayoutContainerNode(node)) return <LayoutView node={node} />;
    if ($isCollapsibleContainerNode(node)) return <CollapsibleView node={node} />;
    if ($isCodeNode(node)) return <CodeView node={node} />;
    if ($isHeadingNode(node)) return <HeadingView node={node} />;
    if ($isQuoteNode(node)) return <QuoteView node={node} />;
    if ($isHorizontalRuleNode(node)) return <DividerView />;
    if ($isListNode(node)) return <PdfListView node={node} />;
    if ($isTextNode(node)) return renderTextNode(node);
    if ($isTableNode(node)) return <PdfTableView node={node} />;
    if ($isExcalidrawNode(node)) return <ExcalidrawPdfImage node={node} />;
    if ($isLinkNode(node)) return renderLinkNode(node);
    if ($isAiContainerNode(node)) return renderAiContainerNode(node);

    return null;
  };

  return nodeElements.map((nodeOrNodes, index) =>
    Array.isArray(nodeOrNodes) ? renderTextNodes(nodeOrNodes, index.toString()) : renderSingleElements(nodeOrNodes),
  );
};

export default LexicalNodePdfView;
