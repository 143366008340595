import { forwardRef, MouseEvent, ReactNode } from 'react';
import { Box, BoxProps, ButtonBase, useTheme } from '@mui/material';

interface SidebarGroupItemProps extends Omit<BoxProps, 'left' | 'title' | 'onClick'> {
  title: ReactNode;
  isActive: boolean;
  left?: ReactNode;
  actions?: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const SidebarGroupItem = forwardRef<HTMLDivElement, SidebarGroupItemProps>(
  ({ sx, title, isActive, onClick, left, actions, ...props }, ref) => {
    const { palette } = useTheme();

    return (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 0.3,
          pr: 0.25,
          pl: 1,
          borderRadius: 1,
          ...sx,
          '&:hover': {
            backgroundColor: palette.grey[25],
            '.SidebarGroupItem__actions': { opacity: 1 },
            // @ts-expect-error
            ...(sx ?? {})['&:hover'],
          },
        }}
        {...props}
      >
        {left}
        <ButtonBase
          disableRipple
          onClick={onClick}
          sx={{
            width: '100%',
            minWidth: 0,
            justifyContent: 'flex-start',
            height: '100%',
            minHeight: 20,
            py: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 'body2.fontSize',
            textAlign: 'left',
            fontWeight: isActive ? 700 : 400,
            color: isActive ? palette.primary.dark : palette.grey[900],
          }}
        >
          {title}
        </ButtonBase>
        <Box className="SidebarGroupItem__actions" sx={{ pr: 1, opacity: 0 }}>
          {actions}
        </Box>
      </Box>
    );
  },
);

export default SidebarGroupItem;
