import { Menu, styled } from '@mui/material';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': { boxShadow: 'none', minWidth: 160 },
  '.MuiMenuItem-root': {
    gap: theme.spacing(1),
    height: 46,
    color: theme.palette.grey[900],
  },
}));
