import { Components, Interpolation, Theme } from '@mui/material/styles';

export const setOfStylesForSize: {
  [key: string]: Interpolation<{ theme: Theme }>;
} = {
  xsmall: ({ theme }) => ({
    borderRadius: 99,
    fontSize: theme.typography.body3.fontSize,
    lineHeight: 'normal',
    padding: theme.spacing(0.5, 1),
    '.MuiButton-startIcon': {
      marginRight: 4,
    },
    '.MuiButton-endIcon': {
      marginLeft: 4,
    },
    '.MuiButton-startIcon > *:nth-of-type(1)': {
      fontSize: theme.typography.body1.fontSize,
    },
  }),
  '2xsmall': ({ theme }) => ({
    borderRadius: 99,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 'normal',
    minWidth: 40,
    height: 24,
    padding: theme.spacing(0, 1),
    '.MuiButton-startIcon': {
      marginRight: 8,
    },
    '.MuiButton-endIcon': {
      marginLeft: 8,
    },
  }),
  '3xsmall': ({ theme }) => ({
    fontSize: theme.typography.body4.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    minWidth: 20,
    height: 15,
    padding: theme.spacing(0, 1),
    borderRadius: 99,
    '.MuiButton-icon, .MuiButton-startIcon': {
      fontSize: theme.typography.body4.fontSize,
      width: 10,
      height: 10,
    },
    '.MuiButton-startIcon': {
      marginRight: 2,
    },
    '.MuiButton-endIcon': {
      marginLeft: 2,
    },
  }),
  small: ({ theme }) => ({
    borderRadius: 8,
    fontSize: theme.typography.body2.fontSize,
    '.MuiButton-startIcon': {
      marginRight: 4,
    },
    '.MuiButton-endIcon': {
      marginLeft: 4,
    },
    '.MuiButton-startIcon > *:nth-of-type(1)': {
      fontSize: theme.typography.base.fontSize,
    },
  }),
};

const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: theme.shape.borderRadius * 2,
      whiteSpace: 'nowrap',
      '&:active, &:hover': { boxShadow: 'none' },
      '&.Mui-disabled': { backgroundColor: theme.palette.grey[50], color: theme.palette.grey[600] },
    }),
    startIcon: { marginLeft: 0, marginRight: 4 },
    endIcon: { marginRight: 0, marginLeft: 20 },
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(1, 1.5),
      '.MuiButton-startIcon': {
        marginRight: 8,
      },
      '.MuiButton-endIcon': {
        marginLeft: 8,
      },
      '& .MuiButton-startIcon>*:nth-of-type(1), & .MuiButton-startIcon': {
        fontSize: theme.typography.h4.fontSize,
      },
    }),
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(0.45, 1.2, 0.45, 1),
    }),
  },
  variants: [
    {
      props: { size: '2xsmall' },
      style: setOfStylesForSize['2xsmall'],
    },
    {
      props: { size: '3xsmall' },
      style: setOfStylesForSize['3xsmall'],
    },
    {
      props: { size: 'xsmall' },
      style: setOfStylesForSize['xsmall'],
    },
    {
      props: { size: 'small' },
      style: setOfStylesForSize['small'],
    },
    {
      props: { color: 'primary', variant: 'contained' },
      style: ({ theme }) => ({
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: theme.shadows[5],
        },
      }),
    },
    {
      props: { color: 'primary', variant: 'outlined' },
      style: ({ theme }) => ({
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      }),
    },
    {
      props: { size: '3xsmall', color: 'secondary' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        '.MuiButton-icon': {
          fill: theme.palette.grey[900],
          color: theme.palette.grey[900],
        },
      }),
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          boxShadow: 'none',
        },
      }),
    },
    {
      props: { color: 'primary', variant: 'outlined' },
      style: ({ theme }) => ({
        color: theme.palette.primary.dark,
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.dark,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.dark,
        },
      }),
    },
    {
      props: { color: 'secondary', variant: 'outlined' },
      style: ({ theme }) => ({
        color: theme.palette.grey[900],
        backgroundColor: 'transparent',
        borderColor: theme.palette.grey[100],
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.grey[100],
        },
      }),
    },
    {
      props: { color: 'secondary', variant: 'text' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.dark,
        '&:hover': {
          boxShadow: 'none',
        },
      }),
    },
    {
      props: { color: 'accent' },
      style: ({ theme }) => ({
        color: theme.palette.accent.contrastText,
        backgroundColor: theme.palette.accent.main,
      }),
    },
  ],
};

export default MuiButton;
