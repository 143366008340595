import { FC, ReactNode } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import DialogHeader, { DialogHeaderProps } from '@/components/Dialog/DialogHeader';

interface DialogAppHeaderProps extends DialogHeaderProps {
  icon: ReactNode;
}

const DialogAppHeader: FC<DialogAppHeaderProps> = ({ sx, icon, title, ...props }) => {
  const { palette } = useTheme();

  return (
    <DialogHeader
      sx={{ px: 2, py: 1.5, ...sx }}
      title={
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              display: 'flex',
              flexShrink: 0,
              justifyContent: 'center',
              alignItems: 'center',
              width: 40,
              height: 40,
              borderRadius: 2,
              boxShadow: 1,
              color: palette.accent.contrastText,
              backgroundColor: palette.background.default,
            }}
          >
            {icon}
          </Box>
          <Typography variant="h3" color="accent.contrastText" fontWeight={700}>
            {title}
          </Typography>
        </Stack>
      }
      {...props}
    />
  );
};

export default DialogAppHeader;
