import { AutocompleteProps, TextField, useTheme } from '@mui/material';
import { BsChevronDown } from 'react-icons/bs';
import Autocomplete from '@mui/material/Autocomplete';

export interface SelectProps<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'popupIcon'> {
  placeholder?: string;
  renderInput?: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>['renderInput'];
}

function Select<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({ placeholder, sx, renderInput, ...props }: SelectProps<Value, Multiple, DisableClearable, FreeSolo>) {
  const { palette, typography, spacing } = useTheme();

  const popperSx = {
    '& .MuiPaper-root.MuiPaper-rounded': { borderRadius: 2 },
    '& .MuiAutocomplete-listbox': {
      p: 0,
      '& .MuiAutocomplete-option': {
        py: 1.5,
        fontSize: 'body2.fontSize',
        borderBottom: `1px solid ${palette.custom.separationLine}`,
      },
      '& .MuiAutocomplete-option:last-child': {
        borderBottom: 0,
      },
      '& .MuiAutocomplete-option[aria-selected=true]': {
        '&, &:hover, &.Mui-focused': {
          backgroundColor: palette.grey[25],
        },
      },
    },
  };

  const autocompleteSx = {
    borderRadius: 2,
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      backgroundColor: palette.background.default,
    },
    '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
      pr: spacing(6),
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      right: spacing(2),
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-popupIndicator': {
      color: palette.text.secondary,
    },
    '& .MuiAutocomplete-input.MuiOutlinedInput-input': {
      py: 1.5,
      px: 2,
      color: palette.text.secondary,
      fontSize: 'body2.fontSize',
    },
    ...sx,
  };

  return (
    <Autocomplete
      popupIcon={<BsChevronDown style={{ fontSize: typography.icons.fontSize }} />}
      componentsProps={{
        popper: {
          sx: popperSx,
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
        },
      }}
      sx={autocompleteSx}
      renderInput={renderInput ?? (params => <TextField {...params} placeholder={placeholder} />)}
      {...props}
    />
  );
}

export default Select;
