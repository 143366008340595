import { useAuth } from '@/hooks/useAuth';

export const enum Feature {
  compare = 'CompareBeta',
  templates = 'TemplatesBeta',
}

export const useFeatureEnabled = () => {
  const { currentUser } = useAuth();
  return { isFeatureEnabled: (feature: Feature) => !!currentUser.tags?.some(tag => tag.name === feature) };
};
