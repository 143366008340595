import { BaseSyntheticEvent, forwardRef, KeyboardEvent } from 'react';
import { Box, SxProps } from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import { Key } from 'ts-key-enum';
import RoundedMentionInput, { RoundedMentionInputProps } from '@/components/RoundedMentionInput/RoundedMentionInput';

interface FormMentionsInputSubmitProps extends RoundedMentionInputProps {
  error?: FieldError;
  handleSubmit?: (e?: BaseSyntheticEvent<object, unknown, unknown> | undefined) => Promise<void>;
  placeholder?: string;
  sx?: SxProps;
  disabled?: boolean;
  currentValue?: string;
  control?: unknown;
  fieldName: string;
}

const FormMentionsInputSubmit = forwardRef<HTMLTextAreaElement | null, FormMentionsInputSubmitProps>(
  ({ handleSubmit, control, fieldName, instanceRef, ...props }, ref) => {
    const onSubmit = (event: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== Key.Enter || event.shiftKey) return;
      handleSubmit && handleSubmit();
    };

    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ zIndex: 1000, position: 'relative', width: '100%' }}>
        <Controller
          name={fieldName}
          control={control as Control | undefined}
          render={({ field }) => (
            <RoundedMentionInput
              key={field.name}
              {...field}
              {...props}
              ref={instance => {
                field.ref(instance);
                if (!ref) return;
                typeof ref === 'function' ? ref(instance) : (ref.current = instance);
              }}
              onKeyDown={onSubmit}
              instanceRef={instanceRef}
            />
          )}
        />
      </Box>
    );
  },
);

export default FormMentionsInputSubmit;
