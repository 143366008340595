import { FC } from 'react';
import { SxProps, Avatar } from '@mui/material';

interface UserAvatarProps {
  sx?: SxProps;
  size?: number;
  userId: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ userId, size, sx = {} }) => (
  <Avatar
    sx={{ ...(size && { height: size, width: size }), ...sx }}
    src={`${process.env.VITE_API_BASE_URL}/v1/users/avatar/${userId}`}
  />
);

export default UserAvatar;
