import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import App from '@/views/Projects/components/Apps/App';
import { useProjectFormDialog } from '@/hooks/useProjectFormDialog';
import { Link } from 'react-router-dom';
import { toOrgKb, toOrgPages, toTemplatePages } from '@/services/linker';
import Icon from '@/components/Icon/Icon';
import { Feature, useFeatureEnabled } from '@/hooks/useFeatureEnabled';

const Apps = () => {
  const { t } = useTranslation('projects');
  const { palette } = useTheme();

  const { showProjectFormDialog } = useProjectFormDialog();
  const { isFeatureEnabled } = useFeatureEnabled();

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: '50%',
        right: 35,
        zIndex: 1,
        display: 'inline-flex',
        gap: 1.25,
        justifyContent: 'center',
        boxShadow: 16,
        alignItems: 'center',
        transform: 'translateY(-50%)',
        borderRadius: 2,
        pt: 2,
        overflow: 'hidden',
        backgroundColor: palette.background.default,
      }}
    >
      <Stack
        gap={1}
        alignItems="center"
        sx={{ borderBottom: `1px solid ${palette.grey[200]}`, width: '100%', pb: 2, maxWidth: 55 }}
      >
        <Icon name="apps" fontSize="large" htmlColor={palette.primary.dark} />
        <Typography sx={{ fontSize: 'body3.fontSize', color: palette.grey[900] }}>Apps</Typography>
      </Stack>
      <App label={t('apps.compare')} iconName="compare" onClick={() => showProjectFormDialog()} isEnabled={false} />
      <App label={t('apps.create')} iconName="create" to={toOrgPages()} component={Link} isEnabled={true} />
      <App label={t('apps.integration')} iconName="connectivity" onClick={() => showProjectFormDialog()} isEnabled={false} />
      <App label={t('apps.orgDatabase')} iconName="orgKb" component={Link} to={toOrgKb()} isEnabled={true} />
      <App
        label={t('apps.templates')}
        iconName="aiDocs"
        component={Link}
        to={toTemplatePages()}
        isEnabled={isFeatureEnabled(Feature.templates)}
      />
    </Stack>
  );
};

export default Apps;
