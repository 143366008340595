import { Box, IconButton, Input, InputAdornment, SxProps, useTheme } from '@mui/material';
import { FC, ReactNode, useRef, useState } from 'react';
import Icon from '@/components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import SidebarCollapseButton from '@/components/Sidebar/components/SidebarCollapseButton';
import { SIDEBAR_SEARCH_Y_PADDING } from '@/components/Sidebar/constants';

export type SidebarSearchProps = {
  sx?: SxProps;
  searchText: string;
  onSearchTextChange: (searchText: string) => void;
  onCollapseClick?: () => void;
  action?: ReactNode;
};

const SidebarSearch: FC<SidebarSearchProps> = ({ sx, searchText, onSearchTextChange, onCollapseClick, action }) => {
  const { t } = useTranslation('common');
  const { palette } = useTheme();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        pr: 2,
        py: SIDEBAR_SEARCH_Y_PADDING,
        borderBottom: `1px solid ${palette.custom.separationLine}`,
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: '100%', height: 24 }}>
        {onCollapseClick && <SidebarCollapseButton onClick={() => onCollapseClick()} />}
        <Input
          inputRef={inputRef}
          placeholder={t('placeholders.searchPlaceholder')}
          autoFocus
          disableUnderline
          value={searchText}
          sx={{ width: '100%', py: SIDEBAR_SEARCH_Y_PADDING, input: { p: 0 } }}
          startAdornment={
            <InputAdornment position="start">
              <Box sx={{ height: 20 }} onClick={() => inputRef.current?.focus()}>
                <Icon name="search" />
              </Box>
            </InputAdornment>
          }
          endAdornment={
            searchText && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => onSearchTextChange('')}>
                  <Icon name="x" fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={event => onSearchTextChange(event.target.value)}
        />
      </Box>

      {!isFocused && !searchText && action}
    </Box>
  );
};

export default SidebarSearch;
