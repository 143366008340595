import { FC } from 'react';
import { Box, MenuItem, MenuItemProps, Stack, useTheme } from '@mui/material';

interface ToolbarColorMenuItemProps extends MenuItemProps {
  isSelected?: boolean;
  color: string;
  type: 'text' | 'background';
}

const ToolbarColorMenuItem: FC<ToolbarColorMenuItemProps> = ({ color, type, children, ...props }) => {
  const { palette } = useTheme();

  return (
    <MenuItem {...props} sx={{ gap: 1, p: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 14,
          height: 14,
          p: 1,
          border: `1px solid ${palette.grey[50]}`,
          borderRadius: 1,
          fontSize: 'body1.fontSize',
          color: type === 'text' ? color : '',
          backgroundColor: type === 'background' ? color : '',
        }}
      >
        A
      </Box>
      <Stack sx={{ ontSize: 'body2.fontSize' }}>{children}</Stack>
    </MenuItem>
  );
};

export default ToolbarColorMenuItem;
