import { FC } from 'react';
import Icon from '@/components/Icon/Icon';
import { IconButton, IconButtonProps, useTheme } from '@mui/material';

interface BlockDeleteButtonProps extends IconButtonProps {}

const BlockDeleteButton: FC<BlockDeleteButtonProps> = ({ sx, ...props }) => {
  const { palette } = useTheme();

  return (
    <IconButton
      className="BlockDeleteButton"
      sx={{
        position: 'absolute',
        right: -6,
        top: -6,
        zIndex: 1,
        display: 'none',
        p: 0,
        width: 17,
        height: 17,
        '&, &:hover': { backgroundColor: palette.background.default },
        ...sx,
      }}
      {...props}
    >
      <Icon name="x" sx={{ fontSize: 12 }} />
    </IconButton>
  );
};

export default BlockDeleteButton;
