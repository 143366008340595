import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  Checklist,
  getGetOrganizationChecklistsOrgIdQueryKey,
  Tag,
  createOrganizationTag,
  deleteOrganizationChecklists,
  useGetOrganizationChecklistsOrgId,
  createOrganizationChecklist,
  updateOrganizationChecklists,
  uploadFile,
} from '@/api/generated';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks/useAuth';
import Dialog from '@/components/Dialog/Dialog';
import { useOrganization } from '@/hooks/useOrganization';
import Icon from '@/components/Icon/Icon';
import ChecklistForm from '@/views/Project/components/ChecklistsManager/ChecklistForm';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { validationSchema } from '@/views/Project/components/ChecklistsManager/validationSchema';
import uniqBy from 'lodash/uniqBy';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { ChecklistsManagerDialogMode } from '@/views/Project/components/ChecklistsManager/context/ChecklistsManagerDialogContext';
import { LoadingButton } from '@mui/lab';
import ChecklistGroup, { ChecklistGroupProps } from './ChecklistGroup';
import { useCreateQuestionsByChecklists } from '@/views/Project/hooks/useCreateQuestionsByChecklists';
import { useAppDispatch } from '@/store';
import { showCreateQuestionDialog } from '@/store/createQuestionDialogSlice';
import { useDropzone } from 'react-dropzone';
import { acceptedFileTypes } from '@/utils/consts';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { getSessionToken } from '@descope/react-sdk';
import { getRandomChecklistIconName } from './ChecklistIcon';
import DialogAppHeader from '@/components/Dialog/DialogAppHeader';
import Sidebar from '@/components/Sidebar/Sidebar';

interface BaseProps {
  isOpen: boolean;
  mode: ChecklistsManagerDialogMode;
  showNewQuestionButton?: boolean;
  onClose: (action?: 'askSingleQuestion') => void;
  onSelect?: (checklists: Checklist[]) => void;
}

interface SelectProps extends BaseProps {
  mode: 'select';
  onSelect: (checklists: Checklist[]) => void;
}

type ChecklistFromFileSourceEventData = {
  status: 'success' | 'in_progress';
  checklist?: Checklist;
};
type EditorChecklistsDialogProps = BaseProps | SelectProps;

export type ChecklistFormValue = z.infer<typeof validationSchema>;

const RUN_SELECTED_HEIGHT = 44;

const ChecklistsManagerDialog: FC<EditorChecklistsDialogProps> = ({ isOpen, mode, showNewQuestionButton, onClose, onSelect }) => {
  const { t } = useTranslation('project');
  const queryClient = useQueryClient();
  const { palette } = useTheme();
  const { currentUser } = useAuth();
  const { organization } = useOrganization();
  const { showConfirmDialog } = useConfirmDialog();

  const [isUploadFile, setIsUploadFile] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [currentChecklistId, setCurrentChecklistId] = useState<string | undefined>();
  const [currentChecklist, setCurrentChecklist] = useState<Checklist>({ name: '', description: '', queries: [] });
  const [isSaving, setIsSaving] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set<string>());
  const [isUserChecklist, setIsUserChecklist] = useState(false);
  const [selectedChecklists, setSelectedChecklists] = useState<string[] | undefined>();
  const checklistStatusChecksInProgress = useRef<Record<string, true>>({});

  const { createQuestionsByChecklists } = useCreateQuestionsByChecklists();
  const dispatch = useAppDispatch();

  const { data: checklists, isLoading } = useGetOrganizationChecklistsOrgId(organization.id, {
    query: { enabled: isOpen },
  });

  const isSelectMode = mode === 'select' || mode === 'select-and-ask-question';
  const canEdit = isUserChecklist || currentUser.isAdmin;

  const selectedQuestions = useMemo(
    () => currentChecklist.queries?.filter(query => selectedIds.has(query._id ?? '')),
    [currentChecklist.queries, selectedIds],
  );

  const selectedAllIds = useMemo(
    () => !!currentChecklist.queries?.length && currentChecklist.queries?.length === selectedIds.size,
    [currentChecklist.queries?.length, selectedIds.size],
  );

  const form = useForm<ChecklistFormValue>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: { _id: undefined, name: '', description: '', checklistQuestions: [] },
    shouldFocusError: false,
  });
  const { handleSubmit, reset, setFocus, getValues } = form;

  const userCheckLists = useMemo(
    () =>
      checklists?.filter(
        ({ createdBy, name }) => createdBy === currentUser._id && name.toLowerCase().includes(filterValue.toLowerCase()),
      ),
    [checklists, currentUser._id, filterValue],
  );
  const orgCheckLists = useMemo(
    () =>
      checklists?.filter(
        ({ createdBy, name }) => createdBy !== currentUser._id && name.toLowerCase().includes(filterValue.toLowerCase()),
      ),
    [checklists, currentUser._id, filterValue],
  );

  const startCheckingChecklistUploadStatus = (checklistId: string) => {
    if (checklistStatusChecksInProgress.current[checklistId]) return;
    checklistStatusChecksInProgress.current[checklistId] = true;

    const sessionToken = getSessionToken();
    fetchEventSource(`${process.env.VITE_API_BASE_URL}/v1/organizations/${organization.id}/checklists/id/${checklistId}/status`, {
      headers: { Authorization: `Bearer ${sessionToken}` },
      onmessage: async event => {
        const { status, checklist } = JSON.parse(event.data) as ChecklistFromFileSourceEventData;
        if (status !== 'success') return;

        queryClient.setQueryData<Checklist[]>(
          getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
          prevChecklists =>
            prevChecklists?.map(prevChecklist => (prevChecklist._id === checklistId ? checklist! : prevChecklist)),
        );
        delete checklistStatusChecksInProgress.current[checklistId];

        if (getValues('_id') === checklistId) {
          reset({
            name: checklist?.name ?? '',
            checklistQuestions: checklist?.queries ?? [],
          });
        }
      },
    });
  };

  const handleGenerateChecklistFromFile = async (files: File[]) => {
    if (isUploadFile) return;

    setIsUploadFile(true);
    try {
      const [file] = files;
      const checklistFromFile = (await uploadFile(organization.id, { file })) as Checklist;
      queryClient.setQueryData<Checklist[]>(getGetOrganizationChecklistsOrgIdQueryKey(organization.id), prevChecklists => [
        ...(prevChecklists ?? []),
        checklistFromFile,
      ]);
      startCheckingChecklistUploadStatus(checklistFromFile._id!);
    } catch (error) {
      console.error('Error while uploading checklist file');
    }
    setIsUploadFile(false);
  };

  const handleGenerateQuestionsFromFile = async (checklistId: string, files: File[]) => {
    if (isUploadFile) return;

    try {
      const [file] = files;
      await uploadFile(organization.id, { file }, { checklist_id: checklistId });
      queryClient.setQueryData<Checklist[]>(getGetOrganizationChecklistsOrgIdQueryKey(organization.id), prevChecklists =>
        (prevChecklists ?? []).map(checklist => (checklistId === checklist._id ? { ...checklist, is_ready: false } : checklist)),
      );
      startCheckingChecklistUploadStatus(checklistId);
    } catch (error) {
      console.error('Error while uploading checklist file');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleGenerateChecklistFromFile,
    disabled: isUploadFile,
    multiple: false,
    accept: acceptedFileTypes,
  });

  useEffect(() => {
    if (isLoading || (!userCheckLists && !orgCheckLists)) return;
    setCurrentChecklistId(userCheckLists?.[0]?._id || orgCheckLists?.[0]?._id);
  }, [isOpen, isLoading]);

  useEffect(() => {
    if (!isOpen || isLoading || !checklists) return;

    checklists.forEach(checklist => {
      if (!checklist.is_ready) startCheckingChecklistUploadStatus(checklist._id!);
    });
  }, [isOpen, isLoading]);

  useEffect(() => {
    const currentChecklistObject = checklists?.find(({ _id }) => _id === currentChecklistId);
    setCurrentChecklist({
      ...currentChecklistObject,
      name: currentChecklistObject?.name ?? '',
      queries: uniqBy(currentChecklistObject?.queries, '_id') ?? [],
    });
    setIsUserChecklist(currentChecklistObject?.createdBy === currentUser._id);
  }, [checklists, currentChecklistId]);

  useEffect(() => {
    const checklist = currentChecklistId ? checklists?.find(({ _id }) => _id === currentChecklistId) : undefined;
    reset({
      _id: checklist?._id,
      name: checklist?.name ?? '',
      description: checklist?.description ?? '',
      checklistQuestions: checklist?.queries ?? [],
      searchQuestionValue: '',
    });
  }, [currentChecklistId]);

  useEffect(() => {
    setSelectedIds(new Set<string>());
  }, [currentChecklistId]);

  const onSaveField = async (formData?: ChecklistFormValue) => {
    setIsSaving(true);
    try {
      const isNewChecklist = !currentChecklist._id;
      const data = formData ?? getValues();
      const nextChecklist = {
        ...currentChecklist,
        name: data.name,
        description: data.description,
        queries: data.checklistQuestions,
      };

      if (!isNewChecklist) {
        queryClient.setQueryData(
          getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
          checklists?.map(checklist => (checklist._id === nextChecklist?._id ? nextChecklist : checklist)),
        );
      }

      const updatedChecklist = isNewChecklist
        ? await createOrganizationChecklist(organization.id, { ...nextChecklist, icon: getRandomChecklistIconName() })
        : await updateOrganizationChecklists(organization.id, currentChecklist._id!, nextChecklist);

      queryClient.setQueryData(
        getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
        currentChecklist._id
          ? checklists?.map(checklist => (checklist._id === updatedChecklist?._id ? updatedChecklist : checklist))
          : [...(checklists ?? []), updatedChecklist],
      );

      if (isNewChecklist) setCurrentChecklistId(updatedChecklist._id);
    } catch (error) {
      console.error(`Error while saving checklist, ${currentChecklist._id}`, error);
    }
    setIsSaving(false);
  };

  const onAddNewChecklist = () => {
    setFocus('name');
    reset({ name: '', description: '', checklistQuestions: [], searchQuestionValue: '' });
    setCurrentChecklist({ _id: '', name: '', queries: [] });
    setCurrentChecklistId(undefined);
  };

  const onDeleteChecklist = async (checklistId?: string) => {
    if (!checklistId) return;

    const result = await showConfirmDialog({
      title: t('queries.checklistsEditDialog.checklistGroup.deleteConfirm.title'),
      confirm: t('queries.checklistsEditDialog.checklistGroup.deleteConfirm.confirm'),
    });
    if (!result) return;

    try {
      await deleteOrganizationChecklists(organization.id, checklistId);
      selectedChecklists?.length && setSelectedChecklists(prev => prev?.filter(id => id !== checklistId));
      queryClient.setQueryData(
        getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
        checklists?.filter(checklist => checklist._id !== checklistId),
      );
      if (checklistId === currentChecklistId) {
        reset({ name: '', description: '', checklistQuestions: [] });
        setCurrentChecklistId(undefined);
      }
    } catch (error) {
      console.error(`Error while deleting checklist, ${checklistId}`, error);
    }
  };

  const onDeleteQuestions = async () => {
    const updatedQuestionList = currentChecklist.queries?.filter(query => !selectedIds.has(query?._id ?? ''));

    queryClient.setQueryData<Checklist[]>(getGetOrganizationChecklistsOrgIdQueryKey(organization.id), prevChecklists => {
      if (!prevChecklists) return;

      const index = prevChecklists.findIndex(checklist => checklist._id === currentChecklistId);
      if (index == -1) return prevChecklists;

      prevChecklists[index] = { ...prevChecklists[index], queries: updatedQuestionList };
      return prevChecklists;
    });

    reset({ checklistQuestions: updatedQuestionList });
    await onSaveField({ name: currentChecklist.name, checklistQuestions: updatedQuestionList });
  };

  const onToggleAll = () => {
    setSelectedIds(selectedAllIds ? new Set<string>() : new Set(currentChecklist.queries?.map(item => item?._id ?? '')));
  };

  const onQuestionToggle = (id?: string) => {
    if (!id) return;

    const nextIds = new Set(selectedIds);
    if (nextIds.has(id)) {
      nextIds.delete(id);
    } else {
      nextIds.add(id);
    }
    setSelectedIds(nextIds);
  };

  const onUpdateQueryTags = useCallback(
    async (newTag: Tag, queryToUpdateId: string) => {
      if (isSaving) return;
      const currentQuery = currentChecklist?.queries?.find(query => query?._id === queryToUpdateId);

      const isTagExist = (currentQuery?.tags || []).find(({ name }) => name === newTag.name);
      if (isTagExist) return;
      const updatedQueries = currentChecklist?.queries?.map(queryItem => {
        if (queryItem._id !== queryToUpdateId) return queryItem;
        return { ...queryItem, tags: [...(queryItem.tags || []), newTag] };
      });

      await createOrganizationTag(organization.id, newTag);
      await onSaveField({ name: currentChecklist.name, checklistQuestions: updatedQueries });
    },
    [currentChecklist, isSaving],
  );

  const onDeleteTag = useCallback(
    async (tag: Tag, queryToUpdateId?: string) => {
      if (isSaving) return;
      const updatedQueries = currentChecklist.queries?.map(queryItem => {
        if (queryItem._id !== queryToUpdateId) return queryItem;
        return { ...queryItem, tags: (queryItem.tags || []).filter(({ name }) => name !== tag.name) };
      });

      onSaveField({ name: currentChecklist.name, checklistQuestions: updatedQueries });
    },
    [currentChecklist, isSaving],
  );

  const onSelectChecklistsItems = useCallback<() => ChecklistGroupProps['onCheckItems'] | undefined>(() => {
    if (!isSelectMode) return undefined;

    return (selectedItems: { checklistId: string; isChecked: boolean }[]) => {
      setSelectedChecklists((prev = []) => {
        const next = [...prev];
        selectedItems.forEach(selectedItem => {
          const index = next.findIndex(selectedId => selectedId === selectedItem.checklistId);
          if (selectedItem.isChecked && index === -1) {
            next.push(selectedItem.checklistId);
          }
          if (!selectedItem.isChecked && index >= 0) {
            next.splice(index, 1);
          }
        });
        return next;
      });
    };
  }, [isSelectMode]);

  const onRunSelected = async () => {
    selectedChecklists && handleRun(selectedChecklists);
  };

  const handleRun = async (ids: string[]) => {
    if (mode === 'select' && onSelect) {
      const selected = checklists?.filter(checklist => ids?.includes(checklist._id as string));
      setSelectedChecklists([]); // NOTE: it have to be reset before dialog close
      selected && onSelect?.(selected);
      return;
    }

    if (mode === 'select-and-ask-question') {
      const isProceed = await createQuestionsByChecklists(ids);
      if (!isProceed) return;
    }

    onClose();
  };

  const calculateChecklistGroupAmount = (list?: Checklist[]) => {
    if (!isSelectMode) return list?.length;

    const selectedCount = list?.reduce((acc, checklist) => {
      if (selectedChecklists?.includes(checklist._id as string)) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return `${selectedCount}/${list?.length}`;
  };

  const onOpenCreateQuestionDialog = () => {
    if (mode === 'select-and-ask-question') {
      dispatch(showCreateQuestionDialog());
    }
    onClose();
  };

  const renderContent = () => (
    <>
      <Sidebar
        searchText={filterValue}
        backgroundColor={palette.grey[25]}
        onSearchTextChange={setFilterValue}
        action={
          <Button
            variant="contained"
            color="accent"
            size="xsmall"
            onClick={onAddNewChecklist}
            startIcon={<Icon name="plus" />}
            sx={{ flex: '0 0 auto', px: 1.25, borderRadius: 99, whiteSpace: 'nowrap' }}
          >
            {t('queries.checklistsEditDialog.newCheckList')}
          </Button>
        }
      >
        <Stack gap={1.25} sx={{ position: 'relative' }}>
          {!!userCheckLists?.length || !!orgCheckLists?.length ? (
            <>
              <ChecklistGroup
                title={t('queries.checklistsEditDialog.checklistGroup.myList', {
                  amount: calculateChecklistGroupAmount(userCheckLists),
                  interpolation: { escapeValue: false },
                })}
                currentChecklistId={currentChecklistId}
                withDeleteButton
                checklists={userCheckLists}
                onDeleteChecklist={onDeleteChecklist}
                onCheckItems={onSelectChecklistsItems()}
                checkedChecklists={selectedChecklists}
                onChecklistClick={setCurrentChecklistId}
              />
              <ChecklistGroup
                title={t('queries.checklistsEditDialog.checklistGroup.name', {
                  name: organization.name,
                  amount: calculateChecklistGroupAmount(orgCheckLists),
                  interpolation: { escapeValue: false },
                })}
                currentChecklistId={currentChecklistId}
                withDeleteButton={currentUser.isAdmin}
                checklists={orgCheckLists}
                checkedChecklists={selectedChecklists}
                onDeleteChecklist={onDeleteChecklist}
                onCheckItems={onSelectChecklistsItems()}
                onChecklistClick={setCurrentChecklistId}
              />
            </>
          ) : (
            <Stack alignItems="center" py={3} gap={2}>
              <Typography>{t('queries.checklistsEditDialog.createNewChecklist')}</Typography>
              <IconButton onClick={onAddNewChecklist} sx={{ backgroundColor: palette.background.default, boxShadow: 1 }}>
                <Icon name="plus" />
              </IconButton>
            </Stack>
          )}
        </Stack>

        <Stack
          sx={{
            position: 'sticky',
            bottom: 0,
            p: 3,
            pt: 1,
            alignItems: 'center',
            backgroundColor: palette.grey[25],
            '&::before': {
              content: '""',
              display: 'block',
              height: RUN_SELECTED_HEIGHT,
              width: '100%',
              position: 'absolute',
              top: -1 * RUN_SELECTED_HEIGHT,
              left: 0,
              zIndex: 10,
              pointerEvents: 'none',
              background: `linear-gradient(0, ${palette.grey[25]}, rgba(0,0,0,0))`,
            },
          }}
        >
          <LoadingButton
            sx={{
              gap: 1,
              alignItems: 'center',
              px: 1.5,
            }}
            color="primary"
            variant="contained"
            loading={isLoading}
            disabled={!((selectedChecklists || []).length > 0)}
            onClick={onRunSelected}
          >
            <Icon name="bulletList" sx={{ width: 16, height: 16 }} />
            <Typography sx={{ fontWeight: 'fontWeightBold', fontSize: 'body1.fontSize' }}>
              {t(`queries.checklistsEditDialog.runSelectedChecklists`)}
            </Typography>
          </LoadingButton>
        </Stack>
      </Sidebar>

      <Box sx={{ flex: 1, height: '100%', py: 3, px: 5 }}>
        <ChecklistForm
          canEdit={canEdit}
          selectedIds={selectedIds}
          onSaveField={onSaveField}
          onQuestionToggle={onQuestionToggle}
          onToggleAll={onToggleAll}
          selectedAllIds={selectedAllIds}
          onDeleteQuestions={onDeleteQuestions}
          onUpdateQueryTags={onUpdateQueryTags}
          onDeleteTag={onDeleteTag}
          checklists={checklists}
          selectedQuestions={selectedQuestions}
          setCurrentChecklistId={setCurrentChecklistId}
          currentChecklist={currentChecklist}
          onRun={() => currentChecklist?._id && handleRun([currentChecklist._id])}
          onGenerateQuestionsFromFile={files => handleGenerateQuestionsFromFile(currentChecklist._id!, files)}
        />
      </Box>
    </>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      component="form"
      onSubmit={handleSubmit(onSaveField)}
      sx={{ '& .MuiBackdrop-root.MuiModal-backdrop': { backgroundColor: 'rgba(0,0,0,0.5)' } }}
      contentSx={{ p: 0 }}
      PaperProps={{
        sx: {
          maxWidth: 1238,
          width: '100%',
          height: '100%',
          borderRadius: 3,
          boxShadow: 18,
          border: `1px solid ${palette.primary.light}`,
          pb: 0,
          overflow: 'hidden',
        },
      }}
    >
      <DialogAppHeader icon={<Icon name="list" fontSize="large" />} title={t('queries.checklistsEditDialog.title')}>
        {showNewQuestionButton && (
          <Button
            variant="text"
            color="secondary"
            size="2xsmall"
            onClick={onOpenCreateQuestionDialog}
            startIcon={<Icon name="chatCircle" />}
            sx={{ px: 1.25 }}
          >
            {t('queries.checklistsEditDialog.askSingleQuestion')}
          </Button>
        )}
        <Button
          variant="contained"
          color="accent"
          size="2xsmall"
          onClick={onAddNewChecklist}
          startIcon={<Icon name="plus" />}
          sx={{ flex: '0 0 auto', px: 1.25, whiteSpace: 'nowrap' }}
        >
          {t('queries.checklistsEditDialog.newCheckList')}
        </Button>
        <LoadingButton
          size="2xsmall"
          variant="contained"
          startIcon={<Icon name="upload" sx={{ width: 16, height: 16 }} />}
          sx={{ px: 1.25 }}
          loading={isUploadFile}
          {...getRootProps()}
        >
          {t(`queries.checklistsEditDialog.createChecklistFromFile`)}
          <input {...getInputProps()} hidden />
        </LoadingButton>
      </DialogAppHeader>

      <FormProvider {...form}>
        <Stack flexDirection="row" sx={{ position: 'relative', zIndex: 2000, height: '100%', overflow: 'hidden' }}>
          {isLoading ? (
            <CircularProgress sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, margin: 'auto' }} />
          ) : (
            renderContent()
          )}
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default ChecklistsManagerDialog;
