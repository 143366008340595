import { FC, KeyboardEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { $nodesOfType, LexicalEditor, SerializedLexicalNode } from 'lexical';
import { useTranslation } from 'react-i18next';
import { useDebounceValue } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import { Key } from 'ts-key-enum';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { zodResolver } from '@hookform/resolvers/zod';
import Icon from '@/components/Icon/Icon';
import SidebarGroupItem from '@/components/Sidebar/components/SidebarGroupItem';
import SidebarGroup from '@/components/Sidebar/components/SidebarGroup';
import {
  useGetAllTemplatePages,
  createTemplatePage,
  useGetTemplateByPageId,
  updateTemplatePage,
  TemplatePageCreate,
  deletePage,
  TemplatePageMetadata,
  useGetProjectsId,
  setPageIsPrivate,
  getFollowUpQuestions,
  Source,
  askAutomate,
  TemplatePageCreateAiBlocks,
  duplicateTemplatePage,
  TemplatePage,
} from '@/api/generated';
import { useAuth } from '@/hooks/useAuth';
import { useLoader } from '@/hooks/useLoader';
import PagesEditor from '@/containers/PagesEditor/PagesEditor';
import { TemplateForm, validationSchema } from '@/views/Templates/validationSchema';
import TextInputWithEditButton from '@/components/TextInputWithEditButton';
import { AskAiFn, Block } from '@/containers/PagesEditor/types';
import AppHeader from '@/components/AppHeader';
import DropBlocksPlugin from '@/containers/PagesEditor/plugins/DropBlocksPlugin';
import { updateTemplatesCache } from '@/utils/updateTemplatesCache';
import { createRootNode } from '@/containers/PagesEditor/utils/createRootNode';
import { useOrganization } from '@/hooks/useOrganization';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import MoreActions, { MenuItemProps } from '@/components/MoreActions/MoreActions';
import { PROJECT_ROUTER_IDS, ROUTER_IDS, toOrgPages, toProjectPages, toProjects } from '@/services/linker';
import { usePageTitle } from '@/hooks/usePageTitle';
import { AiNode } from '@/containers/PagesEditor/nodes/AiNode';
import { VariableNode } from '@/containers/PagesEditor/nodes/VariableNode';
import { TemplateContextProvider } from '@/containers/PagesEditor/context/TemplateContext';
import { usePageFromTemplateDialog } from '@/hooks/usePageFromTemplateDialog';
import ToolbarPlugin from '@/containers/PagesEditor/plugins/ToolbarPlugin/ToolbarPlugin';
import AskAiPlugin from '@/containers/PagesEditor/plugins/AskAiPlugin/AskAiPlugin';
import { AiContainerNode } from '@/containers/PagesEditor/nodes/AiContainerNode';
import { InlineAiNode } from '@/containers/PagesEditor/nodes/InlineAiNode';
import { InlineAiContainerNode } from '@/containers/PagesEditor/nodes/InlineAiContainerNode';
import PlusButtonMenu from '@/components/PlusButtonMenu';
import MenuItemWithIcon from '@/components/MenuItemWithIcon';
import DraggableBlocks from '@/views/Templates/components/DraggableBlocks';
import Sidebar from '@/components/Sidebar/Sidebar';

const TemplatesPage: FC = () => {
  const { palette } = useTheme();
  const { t } = useTranslation('templates');
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const { organization } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showConfirmDialog } = useConfirmDialog();
  const { setPageTitle } = usePageTitle();
  const { showPageFromTemplateDialog } = usePageFromTemplateDialog();
  const { addLoadingId, removeLoadingId } = useLoader();

  const from = searchParams.get('from') as ROUTER_IDS | null;
  const fromSlug = searchParams.get('fromSlug') ?? organization.slug;
  const fromPageId = searchParams.get('fromPageId') ?? undefined;
  const backLink =
    from === ROUTER_IDS.ORG_PAGES
      ? toOrgPages({ pageId: fromPageId })
      : from && PROJECT_ROUTER_IDS.includes(from) && fromSlug
        ? toProjectPages({ projectSlug: fromSlug, pageId: fromPageId })
        : toProjects();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounceValue(searchText, 300);
  const currentTemplateId = searchParams.get('templateId');
  const currentTemplateIdRef = useRef<string | null>(currentTemplateId);
  const currentlyCreatingTemplateRef = useRef<Promise<TemplatePageMetadata | TemplatePage> | null>(null);
  const skipEditorUpdateRef = useRef(false);
  const lexicalEditorRef = useRef<LexicalEditor | null>(null);
  const ignoreNextEditorUpdate = useRef(false);
  const [isEditName, setIsEditName] = useState(false);

  const setCurrentTemplateId = (templateId: string | null, replace = false) => {
    currentlyCreatingTemplateRef.current = null;
    setSearchParams(
      prevParams => {
        const nextParams = new URLSearchParams(prevParams);
        if (templateId) {
          nextParams.set('templateId', templateId);
        } else {
          nextParams.delete('templateId');
        }
        return nextParams;
      },
      { replace },
    );
    currentTemplateIdRef.current = templateId;
  };

  const { control, getValues, reset, setValue } = useForm<TemplateForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: { name: 'New template', content: undefined },
  });

  const {
    data: currentTemplate,
    isLoading: isTemplateLoading,
    error: templateLoadError,
  } = useGetTemplateByPageId(currentTemplateId!, {
    query: { enabled: !!currentTemplateId, gcTime: 0 },
  });
  const { data: templates = [], isLoading: isTemplatesLoading } = useGetAllTemplatePages();
  const { data: currentProject, isLoading: isProjectLoading } = useGetProjectsId(fromSlug!, {
    query: { enabled: !!fromSlug && organization.slug !== fromSlug },
  });

  const title = useMemo(() => {
    if (!currentProject) return t('title');
    return `${t('title')} | ${currentProject.name}`;
  }, [currentProject, fromSlug]);

  const filteredTemplates = useMemo(
    () => templates.filter(template => template.name.toLowerCase().includes(debouncedSearchText.toLowerCase())),
    [templates, debouncedSearchText],
  );
  const userTemplates = useMemo(
    () => filteredTemplates.filter(template => template.created_by === currentUser._id),
    [filteredTemplates],
  );
  const orgTemplates = useMemo(
    () => filteredTemplates.filter(template => template.created_by !== currentUser._id),
    [filteredTemplates],
  );

  useEffect(() => {
    setPageTitle(title);
  }, [title]);

  useEffect(() => {
    if (isTemplatesLoading || currentTemplateId) return;
    const [firstTemplate] = templates;
    setCurrentTemplateId(firstTemplate?._id ?? null, true);
  }, [isTemplatesLoading]);

  useEffect(() => {
    if (templateLoadError) return;
    if (isTemplateLoading) {
      reset({ name: '', content: [] });
      return;
    }

    reset({ name: currentTemplate?.name ?? 'New template', content: currentTemplate?.content ?? [] });
    if (!lexicalEditorRef.current || skipEditorUpdateRef.current) return;

    ignoreNextEditorUpdate.current = true;
    skipEditorUpdateRef.current = false;

    const nextJsonState = JSON.stringify(createRootNode(currentTemplate?.content as Block[] | undefined));
    const editorState = lexicalEditorRef.current.parseEditorState(nextJsonState);
    lexicalEditorRef.current.setEditorState(editorState);
  }, [currentTemplateId, templateLoadError, isTemplateLoading]);

  useEffect(() => {
    if (!templateLoadError || !(templateLoadError instanceof AxiosError) || templateLoadError.response?.status !== 404) return;
    setCurrentTemplateId(null, true);
  }, [templateLoadError]);

  const getCreatingTemplateIdOrCurrentId = async () => {
    if (!currentlyCreatingTemplateRef.current) return currentTemplateIdRef.current;
    const template = await currentlyCreatingTemplateRef.current;
    return template._id!;
  };

  const createOrUpdateTemplate = async (data: Partial<TemplatePageCreate>) => {
    const templateId = await getCreatingTemplateIdOrCurrentId();
    const name = getValues('name');

    if (templateId) {
      try {
        const updatedTemplate = await updateTemplatePage(templateId, { name, ...data });
        updateTemplatesCache(
          { queryClient },
          prevTemplates =>
            prevTemplates?.map(prevTemplate => (prevTemplate._id === updatedTemplate._id ? updatedTemplate : prevTemplate)),
        );
      } catch (error) {
        console.error('Error while creating new template', error);
      }
      return;
    }

    try {
      const templatePromise = createTemplatePage({ name, ...data });
      currentlyCreatingTemplateRef.current = templatePromise;
      const createdTemplate = await currentlyCreatingTemplateRef.current;
      updateTemplatesCache({ queryClient }, prevTemplates => [...(prevTemplates ?? []), createdTemplate]);

      if (templatePromise === currentlyCreatingTemplateRef.current) {
        skipEditorUpdateRef.current = true;
        setCurrentTemplateId(createdTemplate._id!);
      }
    } catch (error) {
      console.error('Error while creating new template', error);
    }
  };

  const handleAddNewTemplate = () => {
    setCurrentTemplateId(null);
    createOrUpdateTemplate({ name: 'New template' });
  };

  const handleTemplateClick = (templateId: string) => {
    skipEditorUpdateRef.current = false;
    setCurrentTemplateId(templateId);
  };

  const handleNameSave = async () => {
    setIsEditName(false);
    createOrUpdateTemplate({ name: getValues('name') });
  };

  const handleNameKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();
    handleNameSave();
  };

  const handleContentChange = useMemo(
    () =>
      debounce(async (content: Block[], markdown: string) => {
        if (ignoreNextEditorUpdate.current) {
          ignoreNextEditorUpdate.current = false;
          return;
        }

        lexicalEditorRef.current?.read(() => {
          const aiBlocks = $nodesOfType(AiNode).map(aiNode => ({
            _id: aiNode.getParent<AiContainerNode>()!.getUuid(),
            block_type: aiNode.getAnswerType(),
            instructions: aiNode.getPrompt(),
          })) satisfies TemplatePageCreateAiBlocks;
          const inlineAiBlocks = $nodesOfType(InlineAiNode).map(aiNode => ({
            _id: aiNode.getParent<InlineAiContainerNode>()!.getUuid(),
            block_type: 'inline_block',
            instructions: aiNode.getPrompt(),
          })) satisfies TemplatePageCreateAiBlocks;
          const variableBlocks = $nodesOfType(VariableNode).map(variableNode => ({
            _id: variableNode.getUuid(),
            block_type: variableNode.getVariableType(),
            settings: variableNode.getVariableType() === 'date' ? { date_type: variableNode.getDateType() } : undefined,
          }));

          setValue('content', content);
          createOrUpdateTemplate({
            content,
            markdown,
            ai_blocks: aiBlocks.concat(inlineAiBlocks),
            variable_blocks: variableBlocks,
          });
        });
      }, 500),
    [currentTemplateId],
  );

  const handleDeleteClick = async (event: MouseEvent, templateId: string) => {
    event.stopPropagation();
    const result = await showConfirmDialog({ title: t('delete.confirm.title') });
    if (!result) return;

    updateTemplatesCache(
      { queryClient },
      prevTemplates => prevTemplates?.filter(prevTemplate => prevTemplate._id !== templateId),
    );
    if (currentTemplateId === templateId) {
      skipEditorUpdateRef.current = false;
      setCurrentTemplateId(null);
    }

    try {
      await deletePage(organization.slug, templateId);
      enqueueSnackbar(t('delete.success'));
    } catch (error) {
      console.error('Error while deleting a template', error);
      enqueueSnackbar(t('delete.error'), { variant: 'error' });
    }
  };

  const handlePrivatePublicClick = async (event: MouseEvent, template: TemplatePageMetadata) => {
    event.stopPropagation();
    const result = await showConfirmDialog({
      title: t('publicPrivate.makePublicConfirm.title'),
      description: t('publicPrivate.makePublicConfirm.description'),
      confirm: t('publicPrivate.makePublicConfirm.confirm'),
    });
    if (!result || !templates) return;

    const newState = !template.isPrivate;
    updateTemplatesCache(
      { queryClient },
      prevTemplates =>
        prevTemplates?.map(prevTemplate =>
          prevTemplate._id === template._id ? { ...prevTemplate, isPrivate: newState } : prevTemplate,
        ),
    );

    try {
      await setPageIsPrivate(organization.slug, template._id!, { is_private: newState });
      enqueueSnackbar(newState ? i18n.t('publicPrivate.makePrivateSuccess') : i18n.t('makePublicConfirm.makePublicSuccess'));
    } catch (error) {
      console.error('Error while change public state of a template', error);
    }
  };

  const handleCreatePageClick = (event: MouseEvent, templateId: string) => {
    event.stopPropagation();
    showPageFromTemplateDialog(fromSlug, templateId);
  };

  const handleDuplicateClick = async (event: MouseEvent, templateId: string) => {
    event.stopPropagation();
    const uuid = crypto.randomUUID();

    try {
      addLoadingId(uuid);
      const templatePromise = duplicateTemplatePage(templateId);
      currentlyCreatingTemplateRef.current = templatePromise;
      const duplicatedTemplate = await currentlyCreatingTemplateRef.current!;
      updateTemplatesCache({ queryClient }, prevTemplates => [...(prevTemplates ?? []), duplicatedTemplate]);

      if (templatePromise === currentlyCreatingTemplateRef.current) {
        skipEditorUpdateRef.current = true;
        setCurrentTemplateId(duplicatedTemplate._id!);
      }
    } catch (error) {
      console.error('Error while duplicating page', templateId, error);
    }
    removeLoadingId(uuid);
  };

  const askAi: AskAiFn = (question, { reference, mentions = [] } = {}) =>
    askAutomate(fromSlug!, {
      question_receive: {
        mentions,
        question,
        reference,
      },
      page_id: currentTemplateIdRef.current!,
    });

  const getSuggestions = async (selectedText?: string) =>
    getFollowUpQuestions({
      source: selectedText ? Source.create_follow_ups : Source.create,
      context: { page_id: currentTemplateIdRef.current ?? '', query_id: '', equipment_id: '', text: '' },
    });

  const getMyTemplatesActions = (template: TemplatePageMetadata) =>
    [
      {
        id: 'createPage',
        children: t('pageCreateDialog.menuAction'),
        onClick: event => handleCreatePageClick(event, template._id!),
      },
      { id: 'delete', children: t('delete.title'), onClick: event => handleDeleteClick(event, template._id!) },
      {
        id: 'publicPrivate',
        children: template.isPrivate ? t('publicPrivate.makePublic') : t('publicPrivate.makePrivate'),
        onClick: event => handlePrivatePublicClick(event, template),
      },
      {
        id: 'duplicate',
        children: t('duplicate'),
        onClick: event => handleDuplicateClick(event, template._id!),
      },
    ] satisfies MenuItemProps[];

  const getOrgTemplatesActions = (template: TemplatePageMetadata): MenuItemProps[] => {
    const items = [
      {
        id: 'createPage',
        children: t('pageCreateDialog.menuAction'),
        onClick: event => handleDeleteClick(event, template._id!),
      },
      {
        id: 'duplicate',
        children: t('duplicate'),
        onClick: event => handleDuplicateClick(event, template._id!),
      },
    ] satisfies MenuItemProps[];

    if (currentUser.isAdmin) {
      items.push({
        id: 'delete',
        children: t('delete.title'),
        onClick: event => handleDeleteClick(event, template._id!),
      });
    }
    return items;
  };

  if (isTemplatesLoading || isProjectLoading)
    return <CircularProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, m: 'auto' }} />;

  const renderItemLeft = (template: TemplatePageMetadata, { isUserTemplate }: { isUserTemplate?: boolean } = {}) => {
    if (isUserTemplate && !template.isPrivate) return <Icon name="users" fontSize="small" />;

    return <Box sx={{ flexShrink: 0, width: 20 }} />;
  };

  const renderSideMenu = () => (
    <Sidebar searchText={searchText} onSearchTextChange={setSearchText}>
      {!filteredTemplates.length && <Box sx={{ p: 2, textAlign: 'center' }}>{t('noTemplates')}</Box>}

      <Stack gap={1.25} sx={{ position: 'relative' }}>
        {!!userTemplates.length && (
          <SidebarGroup title={t('userTemplatesTitle')}>
            {userTemplates.map(template => (
              <SidebarGroupItem
                key={template._id}
                sx={{ ml: -2 }}
                title={template.name}
                isActive={currentTemplateId === template._id}
                onClick={() => handleTemplateClick(template._id!)}
                left={renderItemLeft(template, { isUserTemplate: true })}
                actions={
                  <MoreActions menuItems={getMyTemplatesActions(template)} id={`PagesTemplatesPage__actions_${template._id!}`} />
                }
              />
            ))}
          </SidebarGroup>
        )}
        {!!orgTemplates.length && (
          <SidebarGroup title={t('orgTemplatesTitle')}>
            {orgTemplates.map(template => (
              <SidebarGroupItem
                key={template._id}
                sx={{ ml: -2 }}
                title={template.name}
                isActive={currentTemplateId === template._id}
                onClick={() => handleTemplateClick(template._id!)}
                left={renderItemLeft(template)}
                actions={
                  <MoreActions menuItems={getOrgTemplatesActions(template)} id={`PagesTemplatesPage__actions_${template._id!}`} />
                }
              />
            ))}
          </SidebarGroup>
        )}
      </Stack>
    </Sidebar>
  );

  const renderEditor = () => {
    if (isTemplateLoading && !skipEditorUpdateRef.current)
      return (
        <Stack sx={{ position: 'relative', height: '100%', alignItems: 'stretch', justifyContent: 'stretch' }}>
          <CircularProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, m: 'auto' }} />
        </Stack>
      );

    return (
      <Stack
        sx={{
          alignItems: 'stretch',
          justifyContent: 'stretch',
          height: '100%',
          gap: 1,
          maxWidth: 800,
          m: 'auto',
          pt: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
          <TextInputWithEditButton
            containerSx={{ width: '100%' }}
            isOpened={isEditName}
            isEditable
            autoFocus
            required
            control={control}
            id="name"
            name="name"
            translate="templates"
            size="small"
            onBlur={handleNameSave}
            onKeyDown={handleNameKeyDown}
            onOpenClick={() => setIsEditName(true)}
          />
          <PlusButtonMenu popupId="plus-templates">
            <MenuItemWithIcon
              icon={<Icon name="plus" />}
              text={t('plusMenu.newTemplate')}
              description={t('plusMenu.newTemplateDescription')}
              onClick={handleAddNewTemplate}
            />
          </PlusButtonMenu>
        </Box>

        <Box sx={{ height: '100%', minHeight: 0, mx: -3, mt: 0 }}>
          <TemplateContextProvider processStatus="edit">
            <PagesEditor
              ref={lexicalEditorRef}
              sx={{
                minHeight: '100%',
                height: 'auto',
                mx: 3,
                pt: 2,
                pl: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
              shellSx={{ display: 'grid', gridTemplateRows: 'auto 1fr', height: '100%', mb: -2, pb: 2 }}
              placeholder={t('editorPlaceholder')}
              initialState={currentTemplate?.content as SerializedLexicalNode[]}
              plugins={({ setIsLinkEditMode }) => <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />}
              pluginsWithAnchor={({ anchorElem }) => (
                <>
                  <AskAiPlugin featureName="org_wiki" anchorElem={anchorElem} askAi={askAi} getSuggestions={getSuggestions} />
                  <DropBlocksPlugin anchorElem={anchorElem} />
                </>
              )}
              onChange={handleContentChange}
            />
          </TemplateContextProvider>
        </Box>
      </Stack>
    );
  };

  return (
    <Stack sx={{ display: 'grid', gridTemplateRows: 'auto 1fr', height: '100%', alignItems: 'stretch' }}>
      <AppHeader icon={<Icon name="aiDocs" fontSize="large" />} text={title} backLink={backLink} />
      <Box sx={{ display: 'flex', overflow: 'hidden' }}>
        {renderSideMenu()}
        <Box sx={{ position: 'relative', width: '100%', px: 3, backgroundColor: palette.grey[25] }}>{renderEditor()}</Box>
        <Box sx={{ flex: '0 0 auto', width: 285, height: '100%', backgroundColor: palette.grey[25] }}>
          <DraggableBlocks />
        </Box>
      </Box>
    </Stack>
  );
};

export default TemplatesPage;
