import { useAppDispatch, useAppSelector } from '@/store';
import { selectHelpDialog, toggleHelpDialogVisible } from '@/store/helpDialogSlice';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@/components/Dialog/Dialog';
import Icon from '../Icon/Icon';
import { Stack, Box, Typography, useTheme, Button, Grid } from '@mui/material';
import SearchInput from '../SearchInput';
import { useLearningCenter } from './useLearningCenter';
import { LearningCenterItem } from '@/api/generated';
import Loader from '../Loader/Loader';
import { GridItem } from './GridItem';
import DialogAppHeader from '@/components/Dialog/DialogAppHeader';

export const HelpDialog: FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { isOpened } = useAppSelector(selectHelpDialog);
  const { palette } = useTheme();
  const { learningCenterItems, isLoading, selectedCategory, categories, setSelectedCategory, searchQuery, setSearchQuery } =
    useLearningCenter();
  const [selectedItem, setSelectedItem] = useState<LearningCenterItem | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setSelectedItem(null);
    dispatch(toggleHelpDialogVisible());
  };

  const onItemSelect = (item: LearningCenterItem) => {
    setSelectedItem(item);
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  };

  return (
    <Dialog open={isOpened} onClose={handleClose} PaperProps={{ sx: { width: '100%', mx: 3, maxWidth: 1300 } }}>
      <DialogAppHeader icon={<Icon name="academy" />} title={t('helpDialog.title')}>
        <Button
          variant="contained"
          color="accent"
          size="2xsmall"
          onClick={handleClose}
          startIcon={<Icon name="tools" fontSize="small" />}
          sx={{ px: 1, height: 25 }}
          href="https://calendly.com/pelles-ai/30min"
          target="_blank"
        >
          {t('helpDialog.supportButtonLabel')}
        </Button>
      </DialogAppHeader>

      <Stack>
        {selectedItem && (
          <Stack
            ref={ref}
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              gap: 5,
              py: 3,
              backgroundColor: palette.background.default,
              borderRadius: 2,
              p: 3,
              boxShadow: 24,
              maxWidth: 1180,
              width: '100%',
              mx: 'auto',
              mt: 6.5,
              mb: 3.5,
            }}
          >
            <Box sx={{ width: '100%', height: 400 }}>
              <iframe
                width="100%"
                height={400}
                src={selectedItem.url.replace('watch?v=', 'embed/')}
                title={selectedItem.name}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Box>
            <Stack sx={{ width: '32%', flexShrink: 0 }}>
              <Typography variant="h3" color="text.secondary" fontWeight={500} mb={4} mt={3}>
                {selectedItem.name}
              </Typography>
              <Typography variant="h3" color="text.secondary" fontWeight={400} mb={3}>
                {selectedItem.description}
              </Typography>
              <Typography variant="body2" color="grey.700" fontWeight={400}>
                {selectedItem.duration?.minutes
                  ? t('helpDialog.videoDuration.minutes', { minutes: selectedItem.duration?.minutes })
                  : ''}
                {selectedItem.duration?.seconds
                  ? ` ${t('helpDialog.videoDuration.seconds', { seconds: selectedItem.duration?.seconds })}`
                  : ''}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          alignItems="center"
          py={3}
          sx={{
            backgroundColor: palette.background.default,
            mx: -4,
            px: 4,
            position: 'sticky',
            top: 32,
            zIndex: 10,
          }}
        >
          <Stack direction="row" gap={1.25} flex={1} flexWrap="nowrap">
            <Button
              variant="contained"
              size="small"
              color={selectedCategory === null ? 'primary' : 'secondary'}
              onClick={() => setSelectedCategory(null)}
              sx={{ lineHeight: 1, height: 29 }}
            >
              All
            </Button>
            {categories?.map(category => (
              <Button
                key={category}
                variant="contained"
                size="small"
                color={selectedCategory === category ? 'primary' : 'secondary'}
                sx={{ lineHeight: 1, height: 29 }}
                onClick={() => setSelectedCategory(category)}
              >
                {(category.charAt(0).toUpperCase() + category.slice(1)).replaceAll('_', ' ')}
              </Button>
            ))}
          </Stack>
          <SearchInput
            sx={{ '.MuiInputBase-root': { py: 0.1, height: 29 }, width: 300 }}
            defaultValue={searchQuery || ''}
            onChange={e => setSearchQuery(e.target.value)}
            placeholder={t('helpDialog.searchPlaceholder')}
          />
        </Stack>
        {isLoading ? (
          <Loader id="learning-center" />
        ) : (
          <Grid container spacing={3} pt={3.5} alignItems="stretch">
            {learningCenterItems?.map(item => (
              <Grid key={item._id} item xs={3} md={3} lg={3}>
                <GridItem data={item} select={onItemSelect} />
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    </Dialog>
  );
};
