import { Box, Checkbox, Stack, Typography, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import SidebarGroupItem from '@/components/Sidebar/components/SidebarGroupItem';

type Child = ReactElement<typeof SidebarGroupItem>;

export type SidebarGroupProps = {
  title: string;
  isChecked?: boolean;
  onCheck?: (isChecked: boolean) => void;
  children?: Array<Child> | Child;
};

const SidebarGroup: FC<SidebarGroupProps> = ({ title, isChecked, onCheck, children: items }) => {
  const { palette } = useTheme();

  return (
    <Stack
      sx={{
        pl: 3,
        pr: 1.5,
        pb: 1,
        borderBottom: `1px solid ${palette.custom.separationLine}`,
        '&:first-of-type': { pt: 1 },
        '&:last-of-type': { borderBottom: 'none' },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', py: 0.75 }}>
        {onCheck && (
          <Checkbox
            id={'all'}
            data-testid="checkbox"
            size="medium"
            disableRipple
            sx={{ p: 0, mr: 1, '&:not(.Mui-checked) .MuiSvgIcon-root': { fill: palette.grey['300'] } }}
            onChange={event => onCheck(event.target.checked)}
            checked={isChecked}
          />
        )}
        <Typography component="div" sx={{ fontSize: 'body1.fontSize', fontWeight: 700, color: palette.grey[600] }}>
          {title}
        </Typography>
      </Box>

      {items}
    </Stack>
  );
};

export default SidebarGroup;
