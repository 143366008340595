import { Instance, ViewState } from '@nutrient-sdk/viewer';
import { toggleZoomState } from '@/containers/DocumentEditor/utils/toggleZoomState';

type Params = {
  instance: Instance;
  viewState: ViewState;
  prevViewState: ViewState;
};

export const togglePanAndScrollOnScrollModeChange = ({ instance, viewState, prevViewState }: Params) => {
  if (viewState.scrollMode === prevViewState.scrollMode) return;

  const wheelZoomMode = typeof viewState.zoom === 'object' && viewState.zoom.wheelZoomMode === 'ALWAYS';
  if (viewState.scrollMode === 'PER_SPREAD' && !wheelZoomMode) {
    instance.setViewState(currentViewState => toggleZoomState(currentViewState, true).set('interactionMode', 'PAN'));
  } else if (viewState.scrollMode === 'CONTINUOUS' && wheelZoomMode) {
    instance.setViewState(currentViewState =>
      toggleZoomState(currentViewState, false).set(
        'interactionMode',
        currentViewState.interactionMode === 'PAN' ? null : currentViewState.interactionMode,
      ),
    );
  }
};
