import { Components, Theme } from '@mui/material/styles';

const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      '&:not(:last-child)': { borderBottom: `1px solid ${theme.palette.custom.separationLine}` },
      '&:hover': {
        backgroundColor: theme.palette.grey[25],
      },
    }),
  },
};

export default MuiMenuItem;
