import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Stack, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';
import Dialog from '@/components/Dialog/Dialog';
import { TemplatePage, useGetAllTemplatePages } from '@/api/generated';
import SelectControl from '@/components/SelectControl';
import TextInput from '@/components/TextInput/TextInput';
import { ROUTER_IDS, toTemplatePages } from '@/services/linker';
import useRouteId from '@/hooks/useRouteId';
import { useAppDispatch } from '@/store';
import { createPageFromTemplate } from '@/store/lexicalPagesThunks';
import Icon from '@/components/Icon/Icon';
import DialogTextHeader from '@/components/Dialog/DialogTextHeader';

interface PageFromTemplateDialogProps {
  isOpened: boolean;
  preselectedTemplateId?: string;
  slug: string;
  onClose: (result: false | TemplatePage) => void;
}

const schema = z.object({
  templateId: z.string(),
  instructions: z.string().min(1),
});
type FormValue = z.infer<typeof schema>;

const PageFromTemplateDialog: FC<PageFromTemplateDialogProps> = ({ isOpened, preselectedTemplateId, slug, onClose }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('templates');
  const dispatch = useAppDispatch();
  const routeId = useRouteId();

  const [searchParams] = useSearchParams();
  const fromPageId = searchParams.get('pageId') ?? undefined;

  const [isPageCreating, setIsPageCreating] = useState(false);

  const { control, formState, handleSubmit, getValues, setValue } = useForm<FormValue>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    defaultValues: { templateId: preselectedTemplateId, instructions: '' },
  });

  const { data: templates = [], isLoading: isTemplatesLoading } = useGetAllTemplatePages({ query: { enabled: isOpened } });

  const options = templates.map(template => ({ title: template.name, value: template._id! }));

  const onCreate = async () => {
    const { templateId, instructions } = getValues();

    setIsPageCreating(true);
    const action = await dispatch(createPageFromTemplate({ slug, templateId, instructions }));
    onClose(!action.payload || action.payload instanceof AxiosError ? false : action.payload);
    setIsPageCreating(false);
  };

  useEffect(() => {
    setValue('templateId', preselectedTemplateId!);
    setValue('instructions', '');
  }, [isOpened, preselectedTemplateId]);

  const renderContent = () => {
    if (isTemplatesLoading)
      return <CircularProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, m: 'auto' }} />;

    return (
      <Stack component="form" sx={{ gap: 2 }} onSubmit={handleSubmit(onCreate)}>
        <SelectControl
          label={t('pageCreateDialog.templateLabel')}
          labelSx={{ fontWeight: 700, color: palette.grey[700] }}
          placeholder={t('pageCreateDialog.templatePlaceholder')}
          id="PageFromTemplateFormDialog__templateId"
          name="templateId"
          control={control}
          options={options}
          disableClearable
        />

        <TextInput
          label={t('pageCreateDialog.instructionsLabel')}
          placeholder={t('pageCreateDialog.instructionsPlaceholder')}
          control={control}
          id="PageFromTemplateFormDialog__instructions"
          name="instructions"
          multiline
          minRows={3}
          labelSx={{ fontWeight: 700, color: palette.grey[700] }}
          translate="templates"
          sx={{ '.MuiInputBase-root': { backgroundColor: palette.controls.background } }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: 2, mt: 1 }}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={isPageCreating}
            disabled={!formState.isValid}
            sx={{ minWidth: 150, alignSelf: 'center' }}
          >
            {t('pageCreateDialog.submit')}
          </LoadingButton>
        </Box>
      </Stack>
    );
  };

  return (
    <Dialog open={isOpened} width={630} onClose={() => onClose(false)}>
      <DialogTextHeader title={t('pageCreateDialog.title')}>
        {routeId !== ROUTER_IDS.PAGES_TEMPLATES && (
          <Button
            component={Link}
            to={toTemplatePages({ from: routeId, fromSlug: slug, fromPageId })}
            color="primary"
            variant="contained"
            size="xsmall"
            startIcon={<Icon name="aiDocs" />}
            onClick={() => onClose(false)}
          >
            {t('pageCreateDialog.goToTemplates')}
          </Button>
        )}
      </DialogTextHeader>
      <Box sx={{ position: 'relative', minHeight: 281 }}>{renderContent()}</Box>
    </Dialog>
  );
};

export default PageFromTemplateDialog;
