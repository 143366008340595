import { $applyNodeReplacement, ElementNode, LexicalNode, type SerializedElementNode } from 'lexical';

export type SerializedAiContentNode = SerializedElementNode;

export class AiContentNode extends ElementNode {
  static getType() {
    return 'ai-content';
  }

  static clone(node: AiContentNode): AiContentNode {
    return new AiContentNode(node.__key);
  }

  static importJSON(): AiContentNode {
    return $createAiContentNode();
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.classList.add('AiContentNode');
    element.classList.add(`AiContentNode_${this.getKey()}`);
    return element;
  }

  updateDOM() {
    return false;
  }

  exportJSON(): SerializedAiContentNode {
    return {
      ...super.exportJSON(),
      type: 'ai-content',
      version: 1,
    };
  }
}

export function $createAiContentNode() {
  return $applyNodeReplacement(new AiContentNode());
}

export function $isAiContentNode(node: LexicalNode | null | undefined): node is AiContentNode {
  return node instanceof AiContentNode;
}
