import { FC, ReactNode } from 'react';
import { Box, IconButton, SxProps } from '@mui/material';
import Icon from '@/components/Icon/Icon';

export interface EditableFieldProps {
  containerSx?: SxProps;
  displayedText: ReactNode;
  isOpened: boolean;
  isEditable?: boolean;
  children: ReactNode;
  onOpenClick: () => void;
}

const EditableField: FC<EditableFieldProps> = ({
  containerSx,
  displayedText,
  isOpened,
  isEditable = true,
  children,
  onOpenClick,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', ...containerSx }} className="EditableField">
    {isOpened ? (
      children
    ) : (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          width: 'auto',
          overflow: 'hidden',
          '&:hover .TextInputWithEditButton__edit': { opacity: 1 },
        }}
      >
        {displayedText}
        {isEditable && (
          <IconButton onClick={() => onOpenClick()} className="TextInputWithEditButton__edit" sx={{ opacity: 0 }}>
            <Icon fontSize="small" name="editWithUnderline" sx={{ width: 20, height: 20 }} />
          </IconButton>
        )}
      </Box>
    )}
  </Box>
);

export default EditableField;
