import { FC, ReactNode, useRef, useState } from 'react';
import CloudFilesDialog, { DefaultParams } from '@/views/Projects/components/ProjectFormDialog/components/CloudFilesDialog';
import { DriveFile, DriveFileType, DriveNode } from '@/types';
import { useGooglePicker } from '@/views/Projects/components/ProjectFormDialog/hooks/useGooglePicker';
import {
  CloudFilesDialogContext,
  CloudFilesDialogContextValue,
  CloudFilesDialogErrorCallback,
  CloudFilesDialogParams,
} from '@/contexts/CloudFilesDialogContext';
import { useMicrosoftPicker } from '@/views/Projects/components/ProjectFormDialog/hooks/useMicrosoftPicker';

interface CloudFilesDialogProviderProps {
  children: ReactNode;
}

export const CloudFilesDialogProvider: FC<CloudFilesDialogProviderProps> = ({ children }) => {
  const [driveType, setDriveType] = useState<DriveFileType | 'none'>('none');
  const resolveRef = useRef<(result: DriveFile[] | false) => void>(() => false);
  const defaultParamsRef = useRef<DefaultParams | undefined>(undefined);
  const errorCallbackRef = useRef<CloudFilesDialogErrorCallback>(() => false);

  const { openPicker: openGooglePicker } = useGooglePicker({
    onAction: (picker: google.picker.Picker, response: google.picker.ResponseObject) => {
      const files = response[google.picker.Response.DOCUMENTS];
      if (response[google.picker.Response.ACTION] !== google.picker.Action.PICKED || !files) return;

      picker.dispose();
      resolveRef.current(files);
    },
  });

  const { openPicker: openMicrosoftPicker } = useMicrosoftPicker({
    onFilesSelect: files => onNodesSelect(files.map(file => ({ id: file.id, name: file.name, isFolder: false, native: file }))),
    onError: context => errorCallbackRef.current(context),
  });

  const contextValue: CloudFilesDialogContextValue = {
    showCloudFilesDialog: async (nextDriveType: DriveFileType, { defaultParams, onError }: CloudFilesDialogParams = {}) => {
      errorCallbackRef.current = onError ?? (() => false);
      defaultParamsRef.current = defaultParams;

      try {
        if (nextDriveType == 'google') {
          await openGooglePicker();
        } else if (nextDriveType === 'onedrive') {
          openMicrosoftPicker('onedrive');
        } else {
          setDriveType(nextDriveType);
        }
      } catch (error) {
        onError?.({ type: nextDriveType, error });
      }

      return new Promise<DriveFile[] | false>(resolve => {
        resolveRef.current = resolve;
      });
    },
  };

  const onDialogClose = (result: DriveFile[] | false) => {
    resolveRef.current(result);
    setDriveType('none');
    defaultParamsRef.current = undefined;
    errorCallbackRef.current = () => false;
  };

  const onNodesSelect = (nodes: DriveNode[]) => {
    onDialogClose(nodes.map(node => node.native));
  };

  return (
    <CloudFilesDialogContext.Provider value={contextValue}>
      <CloudFilesDialog
        defaultParams={defaultParamsRef.current}
        driveFilesType={driveType}
        onNodesSelect={onNodesSelect}
        onClose={() => onDialogClose(false)}
        /* eslint-disable-next-line react/jsx-handler-names */
        onError={errorCallbackRef.current}
      />
      {children}
    </CloudFilesDialogContext.Provider>
  );
};
