import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';
import { validationSchema } from '@/views/Projects/components/ProjectFormDialog/validationScheme';
import {
  getGetProjectsIdQueryKey,
  getGetProjectsQueryKey,
  ProjectFull,
  editProject,
  getProjectsId,
  postProjects,
} from '@/api/generated';
import { toProjectHomepage } from '@/services/linker';
import { useEmptyDocumentProject } from '@/views/Project/hooks/useEmptyDocumentProject';

type Params = {
  initialProject?: ProjectFull;
  onAfterSave: (project: ProjectFull) => void;
  onClose: (project?: ProjectFull) => void;
};

export const useProjectForm = ({ initialProject, onClose, onAfterSave }: Params) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setForceDropZoneView } = useEmptyDocumentProject();

  const projectRef = useRef(initialProject);
  const projectPromiseRef = useRef<Promise<ProjectFull>>();
  const [isSaving, setIsSaving] = useState(false);

  const getOrCreateProject = async () => {
    const projectName = form.getValues('name') || 'New Project';

    if (!initialProject && !projectPromiseRef.current && !projectRef.current) {
      projectPromiseRef.current = postProjects({ name: projectName });
    }

    const currentProject = initialProject ?? projectRef.current ?? (await projectPromiseRef.current!);
    projectRef.current = currentProject;
    if (!initialProject) {
      form.setValue('slug', currentProject.slug);
      form.setValue('name', currentProject.name!);
      queryClient.invalidateQueries({ queryKey: [getGetProjectsQueryKey()] });
      await queryClient.prefetchQuery({
        queryKey: getGetProjectsIdQueryKey(currentProject.slug),
        queryFn: () => getProjectsId(currentProject.slug),
      });
    }

    return currentProject;
  };

  const form = useForm<ProjectFormValue>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: { stakeholders: [] },
    shouldFocusError: false,
  });
  const { reset, handleSubmit, getFieldState } = form;

  const resetFormValues = (project?: ProjectFull) => {
    projectRef.current = project;
    projectPromiseRef.current = undefined;
    reset({
      slug: project?.slug,
      name: project?.name ?? '',
      address: project?.address ?? '',
      location: project?.location ?? undefined,
      delivery_method: project?.delivery_method,
      project_size: project?.project_size,
      owner: project?.owner ?? '',
      type: project?.type ?? '',
      stakeholders: project?.stakeholders ?? [],
    });
  };

  const onSaveField = async (formData: ProjectFormValue) => {
    const stakeHolderIsNotValid = formData.stakeholders?.some(
      (_item, index) => getFieldState(`stakeholders.${index}.role`).invalid,
    );
    if (stakeHolderIsNotValid) return;

    setIsSaving(true);
    try {
      if (projectPromiseRef.current) await projectPromiseRef.current;

      const slug = form.getValues('slug');
      const nextProject = slug
        ? await editProject(slug, {
            ...formData,
            name: formData.name === initialProject?.name ? undefined : formData.name,
          })
        : await postProjects(formData);
      form.setValue('slug', nextProject.slug);
      projectRef.current = nextProject;

      setForceDropZoneView(false);
      onAfterSave(nextProject);

      if (initialProject?.slug !== nextProject.slug) {
        navigate(toProjectHomepage({ projectSlug: nextProject.slug }));
      }
    } catch (error) {
      console.error('Error while saving project', error);
    }
    setIsSaving(false);
  };

  const onSave = async (formData: ProjectFormValue) => {
    const stakeHolderIsNotValid = formData.stakeholders?.some(
      (_item, index) => getFieldState(`stakeholders.${index}.role`).invalid,
    );
    if (stakeHolderIsNotValid) return;
    onClose();
  };

  return {
    projectRef,
    form,
    isSaving,
    resetFormValues,
    getOrCreateProject,
    onSubmit: handleSubmit(onSave),
    onSaveField: handleSubmit(onSaveField),
  };
};
