import { createContext, ReactNode, useContext, useMemo, FC } from 'react';
import { GlobalStyles } from '@mui/material';

export type ProcessStatus = 'edit' | 'in-progress' | 'ready';

type ContextValue = {
  processStatus: ProcessStatus;
};

interface TemplateContextProviderProps {
  processStatus: ProcessStatus;
  children: ReactNode;
}

const TemplateContext = createContext<ContextValue>({ processStatus: 'in-progress' });

export const TemplateContextProvider: FC<TemplateContextProviderProps> = ({ processStatus, children }) => {
  const contextValue = useMemo(() => ({ processStatus }), [processStatus]);

  return (
    <TemplateContext.Provider value={contextValue}>
      <GlobalStyles
        styles={({ spacing }) => ({
          '.InlineAiContentNode, .AiContentNode': { display: processStatus === 'ready' ? 'inline' : 'none' },
          '.InlineAiNode, .VariableNode': { display: 'inline-block' },
          '.InlineAiContainerNode': { display: 'inline' },
          '.VariableNode': { paddingLeft: spacing(1), paddingRight: spacing(1) },
        })}
      />
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = () => useContext(TemplateContext);
