import { Components, Theme } from '@mui/material/styles';

const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[2],
      border: '1px solid',
      borderColor: theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius * 2,
      marginTop: theme.spacing(1),
    }),
    list: { padding: 0 },
  },
};

export default MuiMenu;
